import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import Utils from "../../core/Utils";
import { connectToStore } from "../../data/store";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import PrintIcon from "@mui/icons-material/Print";
import RefreshIcon from "@mui/icons-material/Refresh";
import "../../Style.css";
import "../../DateRangePickerWithYear.css";
import "react-calendar/dist/Calendar.css";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import moment from "moment";
import UserHelper from "../../core/UserHelper";
import {
  GenerateGroupedVisitPage,
  GroupedVisitsPage,
  LoginPage,
} from "../../core/PageConstants";
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Loader from "../common/Loader";
import MessagePopup from "../common/MessagePopUp";
import SideBarMenuItems from "../SideBarMenuItems";
import {
  ConfirmTypes,
  GroupedVisitStatuses,
  India,
  MaxDateLimit,
  PaymentMethodTypes,
  SideBarMenuNames,
  UAE,
  USA,
  VisitStatuses,
} from "../../core/Constants";
import { postAsync } from "../../core/serviceClient";
import SearchIcon from "@mui/icons-material/Search";
import Jobcard_Icon from "../../images/Jobcard.png";
import { getCustomerCell, getEmailCell, getMaskedMobileCell } from "../Visits";
import AmountTypography from "../common/controls/AmountTypography";
import UPIComponent from "../common/UPIComponent";
import PaymentTypeDialog from "../common/PaymentTypeDialog";
import CardComponent from "../common/CardComponent";
import { ConfirmDialog } from "../common/ConfirmDialog";
import CountryHelper from "../../core/CountryHelper";

const groupedVisitsActions = {
  Pay: "Pay",
  Save: "Save",
  Print: "Print",
  UPI: "UPI",
};

const duplicateResultTypes = {
  Found: "Found",
  NotFound: "NotFound",
  Error: "Error",
};

const GenerateGroupedVisit = () => {
  document.title = "Generate Grouped Visit";
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAction, setSelectedAction] = useState("");

  //Confirm hooks
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  //Alert Hooks
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [selectedDate, setSelectedDate] = useState([
    Utils.GetStartDate(),
    Utils.GetEndDate(),
  ]);
  const [business, setBusiness] = useState({});
  const [branch, setBranch] = useState({});
  const [countryInfo, setCountryInfo] = useState({});
  const [visits, setVisits] = useState([]);
  const [filteredVisits, setFilteredVisits] = useState([]);
  const [groupedVisit, setGroupedVisit] = useState({});
  const [searchText, setSearchText] = useState("");
  const [selectedVisits, setSelectedVisits] = useState([]);
  const [description, setDescription] = useState("");
  const [user, setUser] = useState({});
  const [total, setTotal] = useState(0);
  const [selectedLabel, setSelectedLabel] = useState({});
  const [activeLabels, setActiveLabels] = useState([]);
  const [isMultiLabel, setIsMultiLabel] = useState(false);
  const [selectedInvoiceLabelJSONString, setSelectedInvoiceLabelJSONString] =
    useState("");
  const [openUPI, setOpenUPI] = useState(false);
  const [selectedPaymentType, setSelectedPaymentType] = useState("");
  const [openPaymentSelector, setOpenPaymentSelector] = useState(false);
  const [upiValue, setUPIValue] = useState(false);
  const [showCardDetails, setShowCardDetails] = useState(false);
  const [nameOnCard, setNameOnCard] = useState("");
  const [lastFourDigits, setLastFourDigits] = useState("");
  const [cardDetailsDisabled, setCardDetailsDisabled] = useState(true);
  const [showNamePopup, setShowNamePopup] = useState(false);
  const [validVisits, setValidVisits] = useState(false);
  const [descriptionValid, setDescriptionValid] = useState(false);
  const [yesText, setYesText] = useState("Yes");
  const [noText, setNoText] = useState("No");

  useEffect(() => {
    checkAuthentication();
  }, []);

  const checkAuthentication = async () => {
    var _localSetting = connectToStore();
    if (
      UserHelper.CheckPermission(
        GenerateGroupedVisitPage,
        navigate,
        _localSetting
      )
    ) {
      var _business = _localSetting.business;
      var _branch = _localSetting.branch;
      var _country = _localSetting.country;
      var _activeLabels = _branch.invoiceLabels.filter((label) => label.active);
      setActiveLabels(_activeLabels);
      setIsMultiLabel(_activeLabels.length > 1);
      setSelectedInvoiceLabelJSONString(JSON.stringify(_activeLabels[0]));
      setUser(_localSetting.user);
      setCountryInfo(_country);
      setBusiness(_business);
      setBranch(_branch);
      if (!UserHelper.IsStateValid(location.state, navigate)) return;

      var startDT = Utils.ConvertToDate(location.state.currentStart);
      var endDT = Utils.ConvertToDate(location.state.currentEnd);
      setSelectedDate([startDT, endDT]);
      var _groupedVisit = location.state.groupedVisit;
      setGroupedVisit(_groupedVisit);
      if (!Utils.IsNullOrEmptyObject(_groupedVisit)) {
        setDescription(_groupedVisit.description);
        setSelectedLabel(
          _activeLabels.find(
            (a) => a.description === _groupedVisit.invoiceLabelDescription
          )
        );
      } else {
        setSelectedLabel(_activeLabels[0]);
      }
      await getVisits(
        _business.id,
        _branch.branchId,
        Utils.GetISODateTimeString(startDT),
        Utils.GetISODateTimeString(endDT),
        _groupedVisit
      );
    }
  };

  useEffect(() => {
    populateSelectedVisits();
  }, [groupedVisit]);

  const populateSelectedVisits = () => {
    if (Utils.IsNullOrEmptyObject(groupedVisit)) {
      setSelectedVisits([]);
      return;
    }
    var _selectedVisits = groupedVisit.visitInformations.map((i) => i.visitId);
    setSelectedVisits(_selectedVisits);
  };

  const getUnpaidVisits = async (businessId, branchId, startDT, endDT) => {
    var queryId = "AllUnPaidJobcardsForPeriod";
    var queryParams = [
      businessId,
      branchId,
      startDT,
      endDT,
      VisitStatuses.Completed,
      VisitStatuses.PartiallyPaid,
    ];
    var _visits = await fetchVisits(queryId, queryParams);
    setVisits(_visits);
  };

  const getVisitsForEdit = async (
    businessId,
    branchId,
    startDT,
    endDT,
    gVisit
  ) => {
    if (Utils.IsNullOrEmptyArray(gVisit)) {
      return;
    }
    var queryId = "JobcardsForCombinedInvoice";
    const visitIds = gVisit.visitInformations
      .map((c) => `'${c.visitId}'`)
      .join(",");
    var queryParams = [
      businessId,
      branchId,
      visitIds,
      startDT,
      endDT,
      VisitStatuses.Completed,
      VisitStatuses.PartiallyPaid,
    ];
    var _visits = await fetchVisits(queryId, queryParams);
    setVisits(_visits);
  };

  const getPaidVisits = async (gVisit) => {
    if (Utils.IsNullOrEmptyArray(gVisit)) {
      return;
    }
    var queryId = "VisitsByIds";
    var queryParams = [
      gVisit.visitInformations.map((c) => `'${c.visitId}'`).join(","),
    ];
    var _visits = await fetchVisits(queryId, queryParams);
    setVisits(_visits);
    setSelectedVisits(_visits.map((v) => v.id));
  };

  const getVisits = async (businessId, branchId, startDT, endDT, gVisit) => {
    if (Utils.IsNullOrEmptyObject(gVisit)) {
      getUnpaidVisits(businessId, branchId, startDT, endDT);
    } else if (
      gVisit.groupedVisitStatus === GroupedVisitStatuses.PaymentReceived
    ) {
      getPaidVisits(gVisit);
    } else {
      getVisitsForEdit(businessId, branchId, startDT, endDT, gVisit);
    }
  };

  const fetchVisits = async (queryId, queryParams) => {
    setIsLoading(true);
    let response = await postAsync(
      "Customer/GetCustomerVisit",
      { Id: "" },
      "",
      "GetAll",
      queryId,
      queryParams
    );
    setIsLoading(false);
    if (response.error) {
      setIsNavigateToLogin(response.isNavigateToLogin);
      setAlert(response.errorMessage);
      return [];
    }
    return response.data;
  };

  const setAlert = (msg) => {
    setAlertMessage(msg);
    setShowAlert(true);
  };

  const onDateChange = async (date) => {
    if (!Utils.IsNullOrEmptyArray(date)) {
      var dateArray = [moment(date[0]), moment(date[1])];
      let dateDiff = dateArray[1].diff(dateArray[0], "days");
      if (dateDiff > MaxDateLimit) {
        setAlert(
          "Please note that the maximum date range you can select is 90 days. You can choose any start date within the past 90 days, but the end date cannot exceed this limit."
        );
      } else {
        setSelectedDate(dateArray);
        await getVisits(
          business.id,
          branch.branchId,
          Utils.GetISODateTimeString(dateArray[0]),
          Utils.GetISODateTimeString(dateArray[1]),
          groupedVisit
        );
      }
    }
  };

  const backNavigation = () => {
    var newState = { ...location.state, groupedVisit: null };
    navigate(GroupedVisitsPage.Path, {
      state: newState,
    });
  };

  const handleAlertClose = () => {
    setShowAlert(false);
    if (isNavigateToLogin) {
      UserHelper.LogOut(dispatch);
      navigate(LoginPage.Path);
    }
  };

  const handleKeyUp = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      search();
    }
  };

  const refresh = async () => {
    await getVisits(
      business.id,
      branch.branchId,
      Utils.GetISODateTimeString(selectedDate[0]),
      Utils.GetISODateTimeString(selectedDate[1]),
      groupedVisit
    );
  };

  useEffect(() => {
    search();
  }, [visits]);

  const search = () => {
    if (Utils.IsNullOrEmpty(searchText)) {
      setFilteredVisits(Utils.IsNullOrEmptyArray(visits) ? [] : visits);
      return;
    }
    if (Utils.IsNullOrEmptyArray(visits)) {
      return;
    }
    var _toFind = Utils.ConvertToUpperCase(searchText);
    var _filteredVisits = visits.filter(
      (item) =>
        Utils.ConvertToUpperCase(item.customerName).includes(_toFind) ||
        Utils.ConvertToUpperCase(item.customerKey).includes(_toFind) ||
        Utils.ConvertToUpperCase(item.customerMobileNumber).includes(_toFind) ||
        Utils.ConvertToUpperCase(item.customerId).includes(_toFind)
    );
    if (Utils.IsNullOrEmptyArray(_filteredVisits)) {
      setFilteredVisits([]);
      return;
    }
    setFilteredVisits(_filteredVisits);
  };

  const handleVisitSelection = (e, visit) => {
    var _checked = e.target.checked;
    var tempSelectedVisits = [...selectedVisits];
    if (_checked) {
      if (Utils.IsNullOrEmptyArray(tempSelectedVisits)) {
        tempSelectedVisits = [visit.id];
      } else {
        if (!tempSelectedVisits.includes(visit.id)) {
          tempSelectedVisits.push(visit.id);
        }
      }
    } else {
      tempSelectedVisits = tempSelectedVisits.filter(
        (value) => value !== visit.id
      );
    }
    setSelectedVisits(tempSelectedVisits);
  };

  const handleSelectAll = (e) => {
    var _checked = e.target.checked;
    var tempSelectedVisits = [...selectedVisits];
    if (_checked) {
      if (!Utils.IsNullOrEmptyArray(filteredVisits)) {
        filteredVisits.forEach((visit) => {
          if (!tempSelectedVisits.includes(visit.id)) {
            tempSelectedVisits.push(visit.id);
          }
        });
      }
    } else {
      tempSelectedVisits = [];
    }
    setSelectedVisits(tempSelectedVisits);
  };

  useEffect(() => {
    recalculateTotals();
    checkSelectedVisits();
  }, [selectedVisits, filteredVisits, selectedLabel, groupedVisit]);

  const checkSelectedVisits = () => {
    if (
      Utils.IsNullOrEmptyArray(selectedVisits) ||
      Utils.IsNullOrEmptyArray(filteredVisits)
    ) {
      setValidVisits(false);
      return;
    }
    setValidVisits(
      filteredVisits
        .map((v) => v.id)
        .some((item) => selectedVisits.includes(item))
    );
  };

  useEffect(() => {
    checkDesscription();
  }, [description]);

  const checkDesscription = () => {
    setDescriptionValid(!Utils.IsNullOrEmpty(description));
  };

  const recalculateTotals = () => {
    if (
      Utils.IsNullOrEmptyArray(filteredVisits) ||
      Utils.IsNullOrEmptyArray(selectedVisits)
    ) {
      setTotal(0);
      return;
    }
    if (
      !Utils.IsNullOrEmptyObject(groupedVisit) &&
      groupedVisit.groupedVisitStatus === GroupedVisitStatuses.PaymentReceived
    ) {
      setTotal(groupedVisit.totalPaymentAmount);
      return;
    }
    var tempVisits = [];
    selectedVisits.forEach((id) => {
      var visit = filteredVisits.find((v) => v.id === id);
      if (!Utils.IsNullOrEmptyObject(visit)) {
        tempVisits.push(visit);
      }
    });
    if (Utils.IsNullOrEmptyArray(tempVisits)) {
      setTotal(0);
      return;
    }
    var _totalAmount = 0;
    var _paidAmount = Utils.ConvertToFloat(
      tempVisits.map((v) => v.paidAmount).reduce((a, b) => a + b)
    );
    if (Utils.IsNullOrEmptyObject(selectedLabel) || selectedLabel.taxIncluded) {
      _totalAmount = Utils.ConvertToFloat(
        tempVisits.map((v) => v.paymentAmount).reduce((a, b) => a + b)
      );
    } else {
      _totalAmount = Utils.ConvertToFloat(
        tempVisits.map((v) => v.spend).reduce((a, b) => a + b)
      );
      var _discountAmount = Utils.ConvertToFloat(
        tempVisits.map((v) => v.discount).reduce((a, b) => a + b)
      );
      _totalAmount = _totalAmount - _discountAmount;
    }
    var _finalAmount = _totalAmount - _paidAmount;
    setTotal(_finalAmount);
  };

  const handleInvoiceLabelChange = (e) => {
    var _label = e.target.value;
    setSelectedInvoiceLabelJSONString(_label);
    setSelectedLabel(JSON.parse(_label));
  };

  const getPaymentAmount = (visit) => {
    if (
      Utils.IsNullOrEmptyObject(groupedVisit) ||
      groupedVisit.groupedVisitStatus === GroupedVisitStatuses.Pending
    ) {
      if (!selectedLabel.taxIncluded) {
        return visit.spend - visit.discount - visit.paidAmount;
      }
      return visit.paymentAmount - visit.paidAmount;
    }
    return visit.paymentAmount;
  };

  const duplicateCheck = async () => {
    setIsLoading(true);
    var qId = Utils.IsNullOrEmptyObject(groupedVisit)
      ? "DuplicateCheckForNew"
      : "DuplicateCheck";
    const visitIds = selectedVisits.map((c) => `'${c}'`).join(",");
    var qParams = [business.id, branch.branchId, visitIds];
    if (!Utils.IsNullOrEmptyObject(groupedVisit)) {
      qParams.push(groupedVisit.id);
    }
    let response = await postAsync(
      "GroupedVisit/GetCombinedVisits",
      "",
      "",
      "",
      qId,
      qParams
    );
    if (response.error) {
      setIsLoading(false);
      setIsNavigateToLogin(response.isNavigateToLogin);
      setAlert(response.errorMessage);
      return duplicateResultTypes.Error;
    }
    if (!Utils.IsNullOrEmptyArray(response.data)) {
      return duplicateResultTypes.Found;
    }
    return duplicateResultTypes.NotFound;
  };

  const proceedSave = async () => {
    if (!isLoading) {
      setIsLoading(true);
    }
    var _tempVisits = [];
    selectedVisits.forEach((id) => {
      var _visit = filteredVisits.find((f) => f.id === id);
      if (!Utils.IsNullOrEmptyObject(_visit)) {
        _tempVisits.push(_visit);
      }
    });
    var request = {
      InvoiceId: Utils.IsNullOrEmptyObject(groupedVisit) ? "" : groupedVisit.id,
      Visits: _tempVisits,
      Description: description,
      InvoiceLabelDescription: selectedLabel.description,
      IsTaxIncluded: selectedLabel.taxIncluded,
    };
    let response = await postAsync(
      "GroupedVisit/Save",
      request,
      "",
      "",
      "",
      []
    );
    setIsLoading(false);
    if (response.error) {
      setIsNavigateToLogin(response.isNavigateToLogin);
      setAlert(response.errorMessage);
      return null;
    }
    setGroupedVisit(response.data);
    return response.data;
  };

  const beginBook = async (nameEntered = false) => {
    setSelectedAction(groupedVisitsActions.Pay);
    if (Utils.IsNullOrEmptyObject(groupedVisit) && !nameEntered) {
      setShowNamePopup(true);
    } else {
      var duplicateResult = await duplicateCheck();
      switch (duplicateResult) {
        case duplicateResultTypes.Error:
          setIsLoading(false);
          break;
        case duplicateResultTypes.Found:
          setIsLoading(false);
          setConfirmMessage(
            "Some visits are present in another group. Do you wish to continue?"
          );
          setYesText("Yes");
          setNoText("No");
          setShowConfirm(true);
          break;
        case duplicateResultTypes.NotFound:
          await proceedSave();
          setOpenPaymentSelector(true);
          break;
      }
    }
  };

  const onSelectPaymentType = async (paymentType) => {
    setSelectedPaymentType(paymentType);
    switch (paymentType) {
      case PaymentMethodTypes.UPI:
        if (
          Utils.IsNullOrEmptyObject(branch.upi) ||
          Utils.IsNullOrEmpty(branch.upi.upiId)
        ) {
          setOpenPaymentSelector(false);
          setSelectedAction(groupedVisitsActions.UPI);
          setConfirmMessage(
            'To generate QR code for your account, please configure your UPI id in settings.\n\n Click "OK" to proceed with collecting UPI payments outside the system.   \n'
          );
          setYesText("Ok");
          setNoText("Cancel");
          setShowConfirm(true);
        } else {
          var newUpiValue =
            "upi://pay?pa=" +
            branch.upi.upiId +
            "&pn=" +
            branch.upi.upiPayeeName +
            "&cu=INR&am=" +
            total;
          setUPIValue(newUpiValue);
          setOpenPaymentSelector(false);
          setOpenUPI(true);
        }
        break;
      case PaymentMethodTypes.Cash:
      case PaymentMethodTypes.NetBanking:
        await processPayment(paymentType);
        break;
      case PaymentMethodTypes.Card:
        setOpenPaymentSelector(false);
        setShowCardDetails(true);
        break;
    }
  };

  useEffect(() => {
    checkCardDetailsFilled();
  }, [nameOnCard, lastFourDigits]);

  const checkCardDetailsFilled = () => {
    var trueCondition =
      !Utils.IsNullOrEmpty(nameOnCard) &&
      !Utils.IsNullOrEmpty(lastFourDigits) &&
      lastFourDigits.length === 4;
    setCardDetailsDisabled(!trueCondition);
  };

  const saveGroupedVisit = async (nameEntered = false) => {
    setSelectedAction(groupedVisitsActions.Save);
    if (Utils.IsNullOrEmptyObject(groupedVisit) && !nameEntered) {
      setShowNamePopup(true);
    } else {
      var duplicateResult = await duplicateCheck();
      switch (duplicateResult) {
        case duplicateResultTypes.Error:
          setIsLoading(false);
          break;
        case duplicateResultTypes.Found:
          setIsLoading(false);
          setConfirmMessage(
            "Some visits are present in another group. Do you wish to continue?"
          );
          setYesText("Yes");
          setNoText("No");
          setShowConfirm(true);
          break;
        case duplicateResultTypes.NotFound:
          await proceedSave();
          backNavigation();
          break;
      }
    }
  };

  const printGroupedVisit = async (nameEntered = false) => {
    setSelectedAction(groupedVisitsActions.Print);
    if (Utils.IsNullOrEmptyObject(groupedVisit) && !nameEntered) {
      setShowNamePopup(true);
    } else {
      var duplicateResult = await duplicateCheck();
      switch (duplicateResult) {
        case duplicateResultTypes.Error:
          setIsLoading(false);
          break;
        case duplicateResultTypes.Found:
          setIsLoading(false);
          setConfirmMessage(
            "Some visits are present in another group. Do you wish to continue?"
          );
          setYesText("Yes");
          setNoText("No");
          setShowConfirm(true);
          break;
        case duplicateResultTypes.NotFound:
          var _groupedVisit =
            !Utils.IsNullOrEmptyObject(groupedVisit) &&
            groupedVisit.groupedVisitStatus ===
              GroupedVisitStatuses.PaymentReceived
              ? groupedVisit
              : await proceedSave();
          var url =
            Utils.GetServiceUrl() +
            "home/GroupedInvoice?culture=" +
            CountryHelper.GetAPICulture(countryInfo.Culture) +
            "&bid=" +
            business.id +
            "&gid=" +
            _groupedVisit.id;
          window.open(encodeURI(url), "_blank");
          break;
      }
    }
  };

  const closeUpi = () => {
    setOpenPaymentSelector(true);
    setOpenUPI(false);
    setSelectedPaymentType("");
  };

  const closeCard = () => {
    setOpenPaymentSelector(true);
    setShowCardDetails(false);
    setSelectedPaymentType("");
  };

  const closeConfirm = async (confirmResult) => {
    setShowConfirm(false);
    switch (confirmResult) {
      case ConfirmTypes.YES:
      case ConfirmTypes.OK:
        switch (selectedAction) {
          case groupedVisitsActions.Pay:
            await proceedSave();
            setOpenPaymentSelector(true);
            break;
          case groupedVisitsActions.Save:
            await proceedSave();
            backNavigation();
            break;
          case groupedVisitsActions.Print:
            var _groupedVisit = await proceedSave();
            var url =
              Utils.GetServiceUrl() +
              "home/GroupedInvoice?culture=" +
              CountryHelper.GetAPICulture(countryInfo.Culture) +
              "&bid=" +
              business.id +
              "&gid=" +
              _groupedVisit.id;
            window.open(encodeURI(url), "_blank");
            break;
          case groupedVisitsActions.UPI:
            await processPayment();
            break;
        }
        break;
      case ConfirmTypes.NO:
      case ConfirmTypes.CLOSE:
        if (selectedAction === groupedVisitsActions.UPI) {
          setOpenPaymentSelector(true);
        }
        break;
      case ConfirmTypes.CANCEL:
        break;
    }
  };

  const onNameOk = async () => {
    setShowNamePopup(false);
    switch (selectedAction) {
      case groupedVisitsActions.Pay:
        await beginBook(true);
        break;
      case groupedVisitsActions.Save:
        await saveGroupedVisit(true);
        break;
      case groupedVisitsActions.Print:
        await printGroupedVisit(true);
        break;
    }
  };

  const onNameClose = () => {
    setShowNamePopup(false);
  };

  const processPayment = async (paymentType) => {
    if (Utils.IsNullOrEmpty(paymentType)) {
      paymentType = selectedPaymentType;
    }
    setIsLoading(true);
    let request = {
      InvoiceId: groupedVisit.id,
      InvoiceDT: Utils.GetISODateTimeString(moment().utc()),
      InvoiceLabel: selectedLabel,
      CardLast4:
        selectedPaymentType === PaymentMethodTypes.Card ? lastFourDigits : "",
      NameOnCard:
        selectedPaymentType === PaymentMethodTypes.Card ? nameOnCard : "",
      PaymentMethod: paymentType,
    };
    let response = await postAsync("GroupedVisit/Pay", request);
    setIsLoading(false);
    if (response.error) {
      setIsNavigateToLogin(response.isNavigateToLogin);
      setAlert(response.errorMessage);
      return;
    }
    backNavigation();
  };

  return (
    <Container maxWidth="false" className="bg-color p-0">
      {/* Loader */}
      <Loader open={isLoading} />

      {/* Name Popup */}
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 300 } }}
        maxWidth="xs"
        open={showNamePopup}
      >
        <DialogContent dividers className="font-size-14">
          <FormControl fullWidth>
            <TextField
              value={description}
              label="Description*"
              error={Utils.IsNullOrEmpty(description)}
              onChange={(e) => setDescription(e.target.value)}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            className="btn btn-secondary"
            variant="contained"
            color="primary"
            onClick={() => onNameClose()}
          >
            Cancel
          </Button>
          <Button
            autoFocus
            className="btn btn-primary white-text"
            variant="contained"
            style={{ backgroundColor: "gray" }}
            disabled={!descriptionValid}
            onClick={async () => await onNameOk()}
          >
            Proceed
          </Button>
        </DialogActions>
      </Dialog>

      {/* Payment Type */}
      <PaymentTypeDialog
        open={openPaymentSelector}
        close={() => setOpenPaymentSelector(false)}
        paymentLinkEnabled={false}
        cash={() => onSelectPaymentType(PaymentMethodTypes.Cash)}
        card={() => onSelectPaymentType(PaymentMethodTypes.Card)}
        upi={() => onSelectPaymentType(PaymentMethodTypes.UPI)}
        netbanking={() => onSelectPaymentType(PaymentMethodTypes.NetBanking)}
        isUS={countryInfo.Code === USA.Code}
        isIndia={countryInfo.Code === India.Code}
      />

      {/*Upi component */}
      <UPIComponent
        open={openUPI}
        close={() => closeUpi()}
        upiValue={upiValue}
        amount={total}
        country={countryInfo}
        ok={async () => await processPayment()}
      />

      {/* Card Component */}
      <CardComponent
        open={showCardDetails}
        close={() => closeCard()}
        isBusinessCountryUAE={countryInfo.Code === UAE.Code}
        cardDetailsDisabled={cardDetailsDisabled}
        nameOnCard={nameOnCard}
        setNameOnCard={setNameOnCard}
        lastFourDigits={lastFourDigits}
        setLastFourDigits={setLastFourDigits}
        ok={async () => await processPayment()}
      />

      {/* Message Popup */}
      <MessagePopup
        msgOpen={showAlert}
        msgText={alertMessage}
        onMsgClose={handleAlertClose}
      />

      {/*Confirm Dialog */}
      <ConfirmDialog
        open={showConfirm}
        message={confirmMessage}
        onClose={closeConfirm}
        yesText={yesText}
        noText={noText}
      />

      {/* Main */}
      <Box>
        <Grid container m={0}>
          <SideBarMenuItems selectedTab={SideBarMenuNames.Overview} />
          <Grid xs={12} className="content-sec">
            <Grid container direction="row" className="">
              <Grid flex="1" spacing={2} padding="20px">
                {/*Title*/}
                <Grid
                  container
                  className="title-sec"
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    level="h2"
                    component="h2"
                    fontWeight="600"
                    fontSize="2rem"
                    className="page-title text-center"
                  >
                    <Button
                      onClick={() => backNavigation()}
                      className="back-btn"
                    >
                      <NavigateBeforeIcon />
                    </Button>
                    {Utils.IsNullOrEmptyObject(groupedVisit)
                      ? "Multi-Visit Payment"
                      : groupedVisit.description}
                  </Typography>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <RefreshIcon
                      sx={{ fontSize: 24, marginRight: "10px" }}
                      onClick={async () => await refresh()}
                    />
                    <Button
                      className="btn btn-secondary"
                      variant="contained"
                      sx={{ marginRight: "10px" }}
                      disabled={!validVisits}
                      onClick={async () => await printGroupedVisit()}
                    >
                      <PrintIcon style={{ color: "#ff9800" }} />
                      <span className="hidden-xs ml-h">Print</span>
                    </Button>
                    {isMultiLabel && (
                      <FormControl className="invoice-label font-size-14">
                        <InputLabel id="demo-simple-select-label">
                          Invoice Label
                        </InputLabel>
                        <Select
                          disabled={
                            !Utils.IsNullOrEmptyObject(groupedVisit) &&
                            groupedVisit.groupedVisitStatus ===
                              GroupedVisitStatuses.PaymentReceived
                          }
                          labelId="demo-simple-select-label"
                          label="Invoice Label"
                          value={selectedInvoiceLabelJSONString || ""}
                          onChange={(e) => handleInvoiceLabelChange(e)}
                        >
                          {!Utils.IsNullOrEmptyArray(activeLabels) &&
                            activeLabels.map((label) => (
                              <MenuItem value={JSON.stringify(label)}>
                                {label.description}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    )}
                    <Grid className="page-title text-center text-right">
                      <DateRangePicker
                        showLeadingZeros={true}
                        format="dd/MM/yyyy"
                        onChange={onDateChange}
                        maxDate={new Date()}
                        value={selectedDate}
                        locale={countryInfo.Culture}
                        clearIcon={null}
                        calendarIcon={
                          <CalendarMonthOutlinedIcon
                            style={{
                              "font-size": "24px",
                              "align-self": "center",
                              color: "#666666",
                            }}
                          />
                        }
                      />
                    </Grid>
                  </Stack>
                </Grid>
                {/*Content */}
                <Grid
                  container
                  xs={12}
                  className="p-lg-2"
                  direction="column"
                  spacing={2}
                >
                  <Stack
                    direction="row"
                    alignSelf="center"
                    spacing={1}
                    style={{ width: "100%" }}
                  >
                    <Grid flex="1">
                      <TextField
                        className="form-control font-size-14"
                        placeholder="Customer Details...."
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        onKeyUp={(e) => handleKeyUp(e)}
                        style={{
                          marginBottom: "15px",
                        }}
                      />
                    </Grid>
                    <Grid>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={() => search()}
                        className="submitBtn cs-btn"
                        fullWidth="true"
                        style={{ height: "52px" }}
                      >
                        <SearchIcon sx={{ fontSize: 22 }} />
                        <span>Search</span>
                      </Button>
                    </Grid>
                  </Stack>
                  <TableContainer component={Paper} className="visit-table" sx={{maxHeight:"380px"}}>
                    <Table stickyHeader area-lang="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <Checkbox
                              disabled={
                                !Utils.IsNullOrEmptyObject(groupedVisit) &&
                                groupedVisit.groupedVisitStatus ===
                                  GroupedVisitStatuses.PaymentReceived
                              }
                              checked={
                                !Utils.IsNullOrEmptyArray(filteredVisits) &&
                                filteredVisits.every((visit) =>
                                  selectedVisits.includes(visit.id)
                                )
                              }
                              onChange={(e) => handleSelectAll(e)}
                            ></Checkbox>
                          </TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell style={{ width: "200px" }}>
                            Customer Details
                          </TableCell>
                          <TableCell className="text-right">Cost</TableCell>
                          <TableCell className="text-right">Discount</TableCell>
                          {selectedLabel.taxIncluded && (
                            <TableCell className="text-right">Tax</TableCell>
                          )}
                          <TableCell className="text-right">Amount</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredVisits.map((row, index) => (
                          <TableRow>
                            <TableCell>
                              <Checkbox
                                disabled={
                                  !Utils.IsNullOrEmptyObject(groupedVisit) &&
                                  groupedVisit.groupedVisitStatus ===
                                    GroupedVisitStatuses.PaymentReceived
                                }
                                checked={selectedVisits.includes(row.id)}
                                onChange={(e) => handleVisitSelection(e, row)}
                              ></Checkbox>
                            </TableCell>
                            <TableCell
                              data-status={row.status}
                              className="visit-card"
                            >
                              <Stack
                                style={{ "text-align": "center" }}
                                direction="row"
                              >
                                <Box
                                  component="img"
                                  src={Jobcard_Icon}
                                  alt="Jobcard"
                                  style={{
                                    width: "24px",
                                  }}
                                />
                                &nbsp;
                                <span className="visit-type">
                                  {Utils.GetStatusText(row.status)}
                                </span>
                              </Stack>
                              <Stack spacing={2} py={1}>
                                {"Jobcard Number: " + row.jobcardNumber}
                              </Stack>
                              {!Utils.IsNullOrEmpty(row.invoiceNumber) && (
                                <Stack spacing={2} py={1}>
                                  {"Invoice Number: " + row.invoiceNumber}
                                </Stack>
                              )}
                            </TableCell>
                            <TableCell>
                              <Typography
                                component="p"
                                className="m-0"
                                fontWeight="800"
                              >
                                {row.serviceDesc}
                              </Typography>
                              {getCustomerCell(row)}
                              {getEmailCell(row)}
                              {getMaskedMobileCell(row, user)}
                            </TableCell>
                            <TableCell className="text-right">
                              <strong className="mb-txt">Cost</strong>
                              <span>
                                {Utils.GetCurrency(row.spend, countryInfo)}
                              </span>
                            </TableCell>
                            <TableCell className="text-right">
                              <strong className="mb-txt">Discount</strong>
                              <span>
                                -{Utils.GetCurrency(row.discount, countryInfo)}
                              </span>
                            </TableCell>
                            {selectedLabel.taxIncluded && (
                              <TableCell className="text-right">
                                <strong className="mb-txt">Tax</strong>
                                <Typography component="p">
                                  <span>
                                    +{Utils.GetCurrency(row.tax, countryInfo)}
                                  </span>
                                </Typography>
                              </TableCell>
                            )}
                            <TableCell className="text-right">
                              <Typography
                                component="p"
                                fontWeight="600"
                                style={{ "font-size": "16px" }}
                              >
                                <span>
                                  {Utils.GetCurrency(
                                    getPaymentAmount(row),
                                    countryInfo
                                  )}
                                </span>
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Stack direction="column" sx={{ marginTop: "10px" }}>
                    <Stack
                      direction="row"
                      alignItems="flex-end"
                      justifyContent="flex-end"
                      spacing={2}
                      className="font-size-14"
                      sx={{
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        paddingLeft: "5px",
                        paddingRight: "5px",
                      }}
                    >
                      <Typography>Total:</Typography>
                      <AmountTypography
                        value={total}
                        country={countryInfo}
                        component="p"
                        fontWeight="600"
                        style={{ "font-size": "16px" }}
                      />
                    </Stack>
                    {(Utils.IsNullOrEmptyObject(groupedVisit) ||
                      groupedVisit.groupedVisitStatus ===
                        GroupedVisitStatuses.Pending) && (
                      <Stack
                        direction="row"
                        alignItems="flex-end"
                        spacing={2}
                        sx={{ marginRight: "5px", marginBottom: "5px" }}
                        justifyContent="flex-end"
                      >
                        <FormControl>
                          <Button
                            className="btn btn-primary"
                            variant="contained"
                            disabled={!validVisits}
                            onClick={async () => await saveGroupedVisit()}
                          >
                            Save
                          </Button>
                        </FormControl>
                        <FormControl>
                          <Button
                            className="btn btn-primary"
                            type="submit"
                            variant="contained"
                            disabled={!validVisits}
                            onClick={async () => await beginBook()}
                          >
                            Pay
                          </Button>
                        </FormControl>
                      </Stack>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
export default GenerateGroupedVisit;
