import {
  Dialog,
  styled,
  Button,
  DialogContent,
  FormControl,
  Stack,
  Typography,
} from "@mui/material";
import QRCode from "react-qr-code";
import AmountTypography from "./controls/AmountTypography";
import Utils from "../../core/Utils";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const UPIComponent = ({ open, close, upiValue, amount, country, ok }) => {
  return (
    <BootstrapDialog
      onClose={close}
      aria-labelledby="customized-dialog-title"
      open={open}
      className=""
    >
      <DialogContent dividers className="font-size-14">
        <Stack className="text-center">
          {!Utils.IsNullOrEmpty(upiValue) && (
            <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={upiValue}
              viewBox={`0 0 256 256`}
            />
          )}
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            justifyContent="center"
          >
            <Typography
              level="h3"
              component="h3"
              fontWeight="600"
              fontSize="1rem"
              className="h3"
            >
              Paying
            </Typography>
            <AmountTypography
              value={amount}
              country={country}
              level="h3"
              component="h3"
              fontWeight="600"
              fontSize="1rem"
              className="h3"
            />
          </Stack>
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          padding="50px 0 0px 0"
          justifyContent="center"
        >
          <FormControl>
            <Button
              className="btn btn-secondary"
              variant="outlined"
              onClick={close}
            >
              Cancel
            </Button>
          </FormControl>
          <FormControl>
            <Button
              className="btn btn-primary"
              type="submit"
              variant="contained"
              onClick={ok}
            >
              Ok
            </Button>
          </FormControl>
        </Stack>
      </DialogContent>
    </BootstrapDialog>
  );
};
export default UPIComponent;
