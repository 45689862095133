import React, { useReducer, useEffect, useState } from "react";
import "../Style.css";
import "../bootstrap.min.css";
import moment from "moment";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useNavigate, useLocation } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import CountryHelper from "../core/CountryHelper";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { postAsync } from "../core/serviceClient";
import {
  getAuthentication,
  getBranch,
  getBusiness,
  getCountry,
  getUser,
} from "../data/localSettingsActions";
import Utils from "../core/Utils";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

// Images

import DeleteIcon from "@mui/icons-material/Delete";

import Slider from "@mui/material/Slider";

import { generateTemporaryVisit } from "../helpers/provisionalInvoiceHelper";
import {
  India,
  ServiceLocationTypes,
  SideBarMenuNames,
  UAE,
  VisitStatuses,
  VisitTypeEnum,
} from "../core/Constants";
import { serviceItemReducer } from "./reducers/serviceItem/serviceItemReducer";
import { serviceItemActionTypes } from "./reducers/serviceItem/serviceItemActionTypes";
import SideBarMenuItems from "./SideBarMenuItems";
import Loader from "./common/Loader";
import MessagePopup from "./common/MessagePopUp";
import CartComponent from "./common/CartComponent";
import {
  convertServiceItemObject,
  getServiceItemFromProduct,
} from "../helpers/productHelper";
import { useDispatch } from "react-redux";
import Preview_Icon from "../images/preview.png";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import { useCallbackPrompt } from "./hooks/useCallbackPrompt";
import PromptDialog from "./common/PromptDialog";
import { Regexes } from "../core/Regexes";
import NumericTextField from "./common/controls/NumericTextField";
import {
  JobCardDetailsPage,
  LoginPage,
  OverviewPage,
  PaymentPage,
} from "../core/PageConstants";
import UserHelper from "../core/UserHelper";
import AddressField from "./common/controls/AddressField";
import RetryPopup from "./common/RetryPopup";
import InsuranceClaimComponent from "./common/InsuranceClaimComponent";
import EstimateOptionsDialog from "./dialogs/EstimateOptionsDialog";
import InsuranceConfirmDialog from "./dialogs/InsuranceConfirmDialog";

const JobcardErrorTypes = {
  None: "None",
  Error: "Error",
  UnableToEdit: "UnableToEdit",
  Refunded: "Refunded",
  LowerPrice: "LowerPrice",
};

const jobcardActions = {
  Saved: "Saved",
  BackNavigationTriggered: "BackNavigationTriggered",
};

const JobCardDetails = () => {
  document.title = "Job Card Details";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [fuelType, setFuelType] = useState("");
  const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);

  const handleChange = (event) => {
    configureDialog();
    setFuelType(event.target.value);
  };

  const [showDialog, setShowDialog] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);
  const [saveDisabled, setSaveDisabled] = useState(false);

  //Alert hooks
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [jobcardAction, setJobcardAction] = useState("");
  const [showRetry, setShowRetry] = useState(false);
  const [retryMessage, setRetryMessage] = useState("");

  //Loader
  const [isLoading, setIsLoading] = useState(false);

  //Common Hooks
  const [pass, setPass] = useState({});
  const [business, setBusiness] = useState({});
  const [branch, setBranch] = useState({});
  const [isEditJobcard, setIsEditJobcard] = useState(false);
  const [countryInfo, setCountryInfo] = useState({});
  const [isUAEBusiness, setIsUAEBusiness] = useState(false);
  const [estimate, setEstimate] = useState({});
  const [serviceItems, itemDispatch] = useReducer(serviceItemReducer, []);
  const [itemComplimentaryList, setItemComplimentaryList] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState({});

  //Customer Hooks
  const [customerId, setCustomerId] = useState("");
  const [customerUserName, setCustomerUserName] = useState("");
  const [customerMobileNumber, setCustomerMobileNumber] = useState("");
  const [customerEmailId, setCustomerEmailId] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [customerGSTINCode, setCustomerGSTINCode] = useState("");
  const [customer, setCustomer] = useState({});

  useEffect(() => {
    poulateCustomerDetails();
  }, [customer]);

  const poulateCustomerDetails = () => {
    if (Utils.IsNullOrEmptyObject(customer)) {
      return;
    }
    setCustomerId(customer.id);
    setCustomerUserName(customer.userName);
    setCustomerMobileNumber(customer.mobileNumber);
    setCustomerEmailId(customer.emailId);
    setCustomerAddress(customer.homeLocation);
    setCustomerGSTINCode(customer.registrationId);
  };

  //Vehicle Hooks
  const [vehicle, setVehicle] = useState({});
  const [customerVehicleNo, setCustomerVehicleNo] = useState("");
  const [customerPlateCode, setCustomerPlateCode] = useState("");
  const [customerEmirate, setCustomerEmirate] = useState("");
  const [plateCodeDisabled, setPlateCodeDisabled] = useState(true);
  const [emirateDisabled, setEmirateDisabled] = useState(true);
  const [customerVehicleMake, setCustomerVehicleMake] = useState("");
  const [customerVehicleModel, setCustomerVehicleModel] = useState("");
  const [customerVehicleYear, setCustomerVehicleYear] = useState("");
  const [customerVehicleColor, setCustomerVehicleColor] = useState("");
  const [customerVehicleFuelType, setCustomerVehicleFuelType] = useState("");
  const [customerVehicleFuelLevel, setCustomerVehicleFuelLevel] = useState(0);
  const [customerVehicleAverageMileage, setCustomerVehicleAverageMileage] =
    useState("");
  const [customerVehicleTyreCondition, setCustomerVehicleTyreCondition] =
    useState("");
  const [customerVehicleengineNo, setCustomerVehicleengineNo] = useState("");
  const [customerVehicleChassisNo, setCustomerVehicleChassisNo] = useState("");
  const [customerVehicleInsData, setCustomerVehicleInsData] = useState({});
  const [odoMeterIn, setOdoMeterIn] = useState("");
  const [odoMeterOut, setOdoMeterOut] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [addressISOCode, setAddressISOCode] = useState("");
  const [isShowConfirmDialogInsDetails, setIsShowConfirmDialogInsDetails] =
    useState(false);
  const [insuranceDetailsProvided, setInsuranceDetailsProvided] =
    useState(false);
  const [insuranceClaimNumber, setInsuranceClaimNumber] = useState("");
  const [insuranceEstimatedRepairDays, setInsuranceEstimatedRepairDays] =
    useState("");
  const [insuranceEstimateValidity, setInsuranceEstimateValidity] =
    useState("");
  const [insuranceType, setInsuranceType] = useState("");
  const [insuranceInDate, setInsuranceInDate] = useState(
    Utils.GetCurrentDate().add(0, "days")
  );
  const [insuranceOutDate, setInsuranceOutDate] = useState(
    Utils.GetCurrentDate().add(1, "days")
  );

  const checkAuthenticationNew = async () => {
    if (UserHelper.CheckPermission(JobCardDetailsPage, navigate)) {
      if (!UserHelper.IsStateValid(location.state, navigate)) {
        return;
      }
      if (Utils.IsNullOrEmptyObject(location.state)) {
        navigate(OverviewPage.Path);
        return;
      }
      let visitDetails = location.state.visit;
      let customerPassDetails = location.state.pass;
      let vehicleDetails = location.state.vehicleDetails;
      let estimate = location.state.estimate;
      if (Utils.IsNullOrEmptyObject(vehicleDetails) && !Utils.IsNullOrEmptyObject(estimate)) {
        vehicleDetails = estimate.vehicleDetails;
      }
      let isJobcardValid =
        !Utils.IsNullOrEmptyObject(visitDetails) ||
        !Utils.IsNullOrEmptyObject(estimate) ||
        (!Utils.IsNullOrEmptyObject(customerPassDetails) &&
          !Utils.IsNullOrEmptyObject(vehicleDetails));
      if (!isJobcardValid) {
        backNavigation();
        return;
      }
      let isNewJobcard =
        isJobcardValid && Utils.IsNullOrEmptyObject(visitDetails);
      setIsEditJobcard(
        !Utils.IsNullOrEmptyObject(location.state) &&
          !Utils.IsNullOrEmpty(location.state.isEdit)
          ? location.state.isEdit
          : false
      );
      let country = getCountry();
      setCountryInfo(country);
      setIsUAEBusiness(CountryHelper.IsBusinessCountryUAE(country));
      setAddressISOCode(country.ISOCode);
      var currentBusiness = getBusiness();
      var currentBranch = getBranch();
      setBusiness(currentBusiness);
      setBranch(currentBranch);
      let activeLabels = currentBranch.invoiceLabels.filter((i) => i.active);
      if (
        isNewJobcard ||
        Utils.IsNullOrEmpty(visitDetails.invoiceLabelDescription)
      ) {
        if (!Utils.IsNullOrEmptyArray(activeLabels)) {
          setSelectedLabel(activeLabels[0]);
        }
      } else {
        let iLabel = currentBranch.invoiceLabels.filter(
          (i) => i.description === visitDetails.invoiceLabelDescription
        );
        if (!Utils.IsNullOrEmptyObject(iLabel)) {
          setSelectedLabel(iLabel);
        } else {
          if (!Utils.IsNullOrEmptyArray(activeLabels)) {
            setSelectedLabel(activeLabels[0]);
          }
        }
      }
      let isEstimate = !Utils.IsNullOrEmptyObject(estimate);
      let passId = isEstimate
        ? currentBusiness.id + estimate.customerId
        : isNewJobcard
          ? customerPassDetails.id
          : currentBusiness.id + visitDetails.customerId;
      setIsLoading(true);
      let response = await postAsync("Customer/GetJobcardDetails", passId);
      setIsLoading(false);
      if (response.error === true) {
        setIsNavigateToLogin(response.isNavigateToLogin);
        setShowRetry(true);
        setRetryMessage(response.errorMessage);
        return;
      }
      setPass(response.data.pass);
      setCustomer(response.data.customer);
      customerPassDetails = response.data.pass;
      let techList = response.data.technicians;
      setTechniciansList(techList);
      var tempMultipleTechnician = populateMultipleTechnicians(
        techList,
        isNewJobcard
      );
      var data = populateJobTypesAndInventoryItems(currentBranch, isNewJobcard);
      if (isNewJobcard && Utils.IsNullOrEmptyObject(estimate)) {
        setVehicle(vehicleDetails);
        setVisitId(
          "vis-" +
          currentBusiness.id +
          customerPassDetails.customerId +
          "-" +
          moment().format("YYYYMMDD-HHmmssSSS")
        );
        setCustomerJobcardDoneBy(getUser().userName);
      } else if (!Utils.IsNullOrEmptyObject(estimate)) {
        setEstimate(estimate);
        setVehicle(estimate.vehicleDetails);
        if (!Utils.IsNullOrEmptyObject(estimate.insuranceClaimDetails)) {
          setInsuranceClaimNumber(estimate.insuranceClaimDetails.insuranceClaimNumber);
          setInsuranceType(estimate.insuranceClaimDetails.insuranceType);
          setInsuranceEstimateValidity(estimate.insuranceClaimDetails.insuranceEstimateValidity);
          setInsuranceEstimatedRepairDays(estimate.insuranceClaimDetails.insuranceEstimatedRepairDays);
          if (
            !Utils.IsDefaultDate(estimate.insuranceClaimDetails.insuranceInDate)
          ) {
            setInsuranceInDate(
              moment(estimate.insuranceClaimDetails.insuranceInDate)
            );
          }
          if (
            !Utils.IsDefaultDate(estimate.insuranceClaimDetails.insuranceOutDate)
          ) {
            setInsuranceOutDate(
              moment(estimate.insuranceClaimDetails.insuranceOutDate)
            );
          }
        }
        setVisitId(
          "vis-" +
          currentBusiness.id +
          customerPassDetails.customerId +
          "-" +
          moment().format("YYYYMMDD-HHmmssSSS")
        );
        setCustomerComments(estimate.comments);
        populateFromVisitServiceItems(estimate.serviceItems);
        setCustomerJobcardDoneBy(getUser().userName);
      } else {
        setVehicle(visitDetails.vehicleDetails);
        modifyServiceDetails(
          visitDetails,
          data.tempJobTypes,
          data.tempInventoryItems,
          tempMultipleTechnician,
          techList
        );
        setVisit(visitDetails);
      }
    }
  };

  const onCloseRetry = async () => {
    setShowRetry(false);
    if (isNavigateToLogin) {
      UserHelper.LogOut(dispatch);
      navigate(LoginPage.Path);
      return;
    }
    await checkAuthenticationNew();
  };

  const checkAuthentication = async () => {
    try {
      if (UserHelper.CheckPermission(JobCardDetailsPage, navigate)) {
        if (!UserHelper.IsStateValid(location.state, navigate)) {
          return;
        }
        setIsLoading(true);
        var visitDetails = location.state.visit;
        var customerPassDetails = location.state.pass;
        var vehicleDetails = location.state.vehicleDetails;
        var estimate = location.state.estimate;
        var isJobcardValid =
          !Utils.IsNullOrEmptyObject(visitDetails) ||
          !Utils.IsNullOrEmptyObject(estimate) ||
          (!Utils.IsNullOrEmptyObject(customerPassDetails) &&
            !Utils.IsNullOrEmptyObject(vehicleDetails));
        var isNewJobcard =
          isJobcardValid && Utils.IsNullOrEmptyObject(visitDetails);
        setIsEditJobcard(
          !Utils.IsNullOrEmptyObject(location.state) &&
            !Utils.IsNullOrEmpty(location.state.isEdit)
            ? location.state.isEdit
            : false
        );
        var country = getCountry();
        setCountryInfo(country);
        setIsUAEBusiness(CountryHelper.IsBusinessCountryUAE(country));
        setAddressISOCode(country.ISOCode);
        var currentBusiness = getBusiness();
        if (Utils.IsNullOrEmptyObject(estimate)) {
          if (isJobcardValid && !isNewJobcard) {
            var customerPass = await postAsync(
              "Customer/GetCustomerPass",
              {
                BusinessId: currentBusiness.id,
                Id: currentBusiness.id + visitDetails.customerId,
              },
              "",
              "Get"
            );
            if (!customerPass.error) {
              setPass(customerPass.data);
            }
          } else {
            setPass(customerPassDetails);
          }
        } else {
          if (isJobcardValid) {
            var customerPass = await postAsync(
              "Customer/GetCustomerPass",
              {
                BusinessId: currentBusiness.id,
                Id: currentBusiness.id + estimate.customerId,
              },
              "",
              "Get"
            );
            if (!customerPass.error) {
              customerPassDetails = customerPass.data;
              setPass(customerPass.data);
            }
          } else {
            setPass(customerPassDetails);
          }
        }
        var currentBranch = getBranch();
        setBusiness(currentBusiness);
        setBranch(currentBranch);
        if (!isJobcardValid) {
          navigate(Utils.GetLastArrayElement(location.state.navigationStack), {
            state: {
              ...location.state,
              searchText:
                location.state && location.state.searchText
                  ? location.state.searchText
                  : "",
              navigationStack: Utils.RemoveLastElementFromArray(
                location.state.navigationStack
              ),
            },
          });
          return;
        }
        var techList = await getTechnicians();
        setTechniciansList(techList);
        var tempMultipleTechnician = populateMultipleTechnicians(
          techList,
          isNewJobcard
        );
        var data = populateJobTypesAndInventoryItems(
          currentBranch,
          isNewJobcard
        );
        var activeLabels = currentBranch.invoiceLabels.filter((i) => i.active);
        if (isNewJobcard && Utils.IsNullOrEmptyObject(estimate)) {
          if (!Utils.IsNullOrEmptyArray(activeLabels)) {
            setSelectedLabel(activeLabels[0]);
          }
          setCustomer(await getCustomer(customerPassDetails.customerId));
          setVehicle(vehicleDetails);
          setVisitId(
            "vis-" +
            currentBusiness.id +
            customerPassDetails.customerId +
            "-" +
            moment().format("YYYYMMDD-HHmmssSSS")
          );
          setCustomerJobcardDoneBy(getUser().userName);
        } else if (!Utils.IsNullOrEmptyObject(estimate)) {
          setEstimate(estimate);
          setCustomer(await getCustomer(customerPassDetails.customerId));
          setVehicle(estimate.vehicleDetails);
          setVisitId(
            "vis-" +
            currentBusiness.id +
            customerPassDetails.customerId +
            "-" +
            moment().format("YYYYMMDD-HHmmssSSS")
          );
          setCustomerComments(estimate.comments);
          populateFromVisitServiceItems(estimate.serviceItems);
          setCustomerJobcardDoneBy(getUser().userName);
        } else {
          var iLabel = activeLabels.find(
            (i) => i.description === visitDetails.invoiceLabelDescription
          );
          if (Utils.IsNullOrEmptyObject(iLabel)) {
            iLabel = currentBranch.invoiceLabels.filter((i) => i.active)[0];
          }
          setSelectedLabel(iLabel);
          setCustomer(await getCustomer(visitDetails.customerId));
          setVehicle(visitDetails.vehicleDetails);
          modifyServiceDetails(
            visitDetails,
            data.tempJobTypes,
            data.tempInventoryItems,
            tempMultipleTechnician,
            techList
          );
          setVisit(visitDetails);
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setAlert(error.message);
    }
  };

  useEffect(() => {
    checkAuthenticationNew();
  }, []);

  useEffect(() => {
    poulateVehicleDetails();
  }, [vehicle]);

  const poulateVehicleDetails = () => {
    if (Utils.IsNullOrEmptyObject(vehicle)) {
      return;
    }
    setCustomerVehicleNo(vehicle.number);
    setCustomerEmirate(vehicle.emirate);
    setCustomerPlateCode(vehicle.plateCode);
    setCustomerVehicleMake(vehicle.make);
    setCustomerVehicleModel(vehicle.model);
    setCustomerVehicleYear(vehicle.year);
    setCustomerVehicleColor(vehicle.color);
    setCustomerVehicleFuelType(vehicle.fuelType);
    setCustomerVehicleFuelLevel(vehicle.fuelLevel);
    setCustomerVehicleChassisNo(vehicle.chassisNumber);
    setCustomerVehicleengineNo(vehicle.engineNumber);
    setCustomerVehicleInsData(vehicle.vehicleInsuranceDetails);
    setCustomerVehicleTyreCondition(vehicle.tyreCondition);
    setCustomerVehicleAverageMileage(vehicle.averageMileage);
    setOdoMeterIn(Utils.ConvertToString(vehicle.kmDriven));
    //setOdoMeterOut(Utils.ConvertToString(vehicle.kmDrivenOut));
    setVehicleType(vehicle.vehicleType);
  };

  //Service Details Hooks
  const [vehicleImageUrls, setVehicleImageUrls] = useState([]);
  const [techniciansList, setTechniciansList] = useState([]);
  const [serviceAdvisor, setServiceAdvisor] = useState();
  const [jobTypesModel, setJobTypesModel] = useState([]);
  const [inventoryItemsModel, setInventoryItemsModel] = useState([]);
  const [multipleTechniciansModel, setMultipleTechniciansModel] = useState([]);
  const [promisedDate, setPromisedDate] = useState(
    Utils.GetCurrentDate().add(1, "days")
  );
  const [nextServiceInDays, setNextServiceInDays] = useState("60");
  const [nextServiceDT, setNextServiceDT] = useState(
    Utils.GetCurrentDate().add(parseInt(nextServiceInDays), "days")
  );
  const [customerComments, setCustomerComments] = useState("");
  const [customerJobcardInspBy, setCustomerJobcardInspBy] = useState("");
  const [customerJobcardDoneBy, setCustomerJobcardDoneBy] = useState("");
  const [visitId, setVisitId] = useState("");

  const [visit, setVisit] = useState({});
  const [showEstimateOptions, setShowEstimateOptions] = useState(false);
  const [serviceStartLocation, setServiceStartLocation] = useState({});
  const [deleteImageUrls, setDeleteImageUrls] = useState([]);

  const modifyServiceDetails = (
    currentVisit,
    jobTypes,
    inventoryItems,
    multipleTechnicians,
    techList
  ) => {
    if (Utils.IsNullOrEmptyObject(currentVisit)) {
      return;
    }
    const tempJobTypes = [...jobTypes];
    const tempInventoryItems = [...inventoryItems];
    const tempMultipleTechnicians = [...multipleTechnicians];
    if (
      !Utils.IsNullOrEmptyArray(currentVisit.vehicleServiceDetails.jobTypes)
    ) {
      currentVisit.vehicleServiceDetails.jobTypes.forEach((item) => {
        var jobType = tempJobTypes.find((v) => v.value === item);
        if (jobType != null) jobType.isChecked = true;
      });
    }
    if (
      !Utils.IsNullOrEmptyArray(
        currentVisit.vehicleServiceDetails.inventoryData
      )
    ) {
      currentVisit.vehicleServiceDetails.inventoryData.forEach((item) => {
        var inventoryItem = tempInventoryItems.find((v) => v.key === item.name);
        if (inventoryItem != null) {
          inventoryItem.isChecked = true;
          inventoryItem.additionalData = item.additionalData;
        }
      });
    }
    if (
      !Utils.IsNullOrEmptyArray(currentVisit.vehicleServiceDetails.technicians)
    ) {
      currentVisit.vehicleServiceDetails.technicians.forEach((tech, i) => {
        tempMultipleTechnicians[i].isChecked = true;
      });
    }
    const selectedAdvisor = !Utils.IsNullOrEmptyArray(techList)
      ? JSON.stringify(
        techList.find(
          (t) => t.id === currentVisit.vehicleServiceDetails.technicianId
        )
      )
      : "";
    if (!Utils.IsNullOrEmpty(selectedAdvisor)) {
      setServiceAdvisor(selectedAdvisor);
    }
    setJobTypesModel(tempJobTypes);
    setMultipleTechniciansModel(tempMultipleTechnicians);
    setInventoryItemsModel(tempInventoryItems);
    setNextServiceInDays(
      currentVisit.vehicleServiceDetails.nextServiceInDays.toString()
    );
    setVehicleImageUrls(currentVisit.vehicleServiceDetails.imageUrls);
    setVisitId(currentVisit.id);
    setCustomerComments(currentVisit.vehicleServiceDetails.customerRequest);
    setCustomerJobcardDoneBy(currentVisit.vehicleServiceDetails.jobCardDoneBy);
    setInsuranceClaimNumber(
      currentVisit.vehicleServiceDetails.insuranceClaimNumber
    );
    setInsuranceEstimatedRepairDays(
      currentVisit.vehicleServiceDetails.insuranceEstimatedRepairDays
    );
    setInsuranceEstimateValidity(
      currentVisit.vehicleServiceDetails.insuranceEstimateValidity
    );
    setInsuranceType(currentVisit.vehicleServiceDetails.insuranceType);
    if (
      !Utils.IsDefaultDate(currentVisit.vehicleServiceDetails.insuranceInDate)
    ) {
      setInsuranceInDate(
        moment(currentVisit.vehicleServiceDetails.insuranceInDate)
      );
    }
    if (
      !Utils.IsDefaultDate(currentVisit.vehicleServiceDetails.insuranceOutDate)
    ) {
      setInsuranceOutDate(
        moment(currentVisit.vehicleServiceDetails.insuranceOutDate)
      );
    }
    setCustomerJobcardInspBy(
      currentVisit.vehicleServiceDetails.jobInspectionBy
    );
    setSignatureImage(currentVisit.vehicleServiceDetails.customerSignatureUrl);
    populateFromVisitServiceItems(currentVisit.serviceItems);
    if (!Utils.IsNullOrEmptyArray(currentVisit.serviceLocations)) {
      setServiceStartLocation(
        currentVisit.serviceLocations.filter(
          (c) =>
            c.serviceLocationType === ServiceLocationTypes.ServiceStartLocation
        )[0]
      );
    }
  };

  const setAlert = (msg) => {
    setAlertMessage(msg);
    setShowAlert(true);
  };

  useEffect(() => {
    setOdoMeterOut(Utils.ConvertToString(odoMeterIn));
  }, [odoMeterIn]);

  const populateMultipleTechnicians = (techList, isNewJobcard) => {
    if (Utils.IsNullOrEmptyArray(techList)) {
      return [];
    }
    var tempMultipleTechnicians = [];
    techList.forEach((tech) => {
      tempMultipleTechnicians.push({
        TechnicianId: tech.id,
        TechnicianName: tech.firstName,
        Status: "",
        isChecked: false,
      });
    });
    if (isNewJobcard) {
      setMultipleTechniciansModel(tempMultipleTechnicians);
    }
    return tempMultipleTechnicians;
  };

  const getTechnicians = async () => {
    let result = await postAsync("Account/GetUsers", {
      Role: "Technician",
    });
    if (result.error === true) {
      setIsNavigateToLogin(result.isNavigateToLogin);
      setAlert(result.errorMessage);
      return;
    }
    return result.data.userProfiles;
  };

  const getCustomer = async (customerId) => {
    let result = await postAsync(
      "Customer/GetCustomer",
      {
        BusinessId: business.businessId,
        Id: customerId,
      },
      "",
      "Get"
    );
    if (result.error === true) {
      setIsNavigateToLogin(result.isNavigateToLogin);
      setAlert(result.errorMessage);
      return null;
    }
    return result.data;
  };

  const populateJobTypesAndInventoryItems = (currentBranch, isNewJobcard) => {
    //Populating Jobtypes
    var tempJobTypes = [];
    currentBranch.jobTypes.forEach((item) => {
      tempJobTypes.push({ value: item, isChecked: false });
    });

    //Populating Inventory Items
    var tempInventoryItems = [];
    Object.entries(currentBranch.inventoryItem).forEach(([key, value]) => {
      tempInventoryItems.push({
        key: key,
        value: value,
        isChecked: false,
        additionalData: "",
      });
    });
    if (isNewJobcard) {
      setJobTypesModel(tempJobTypes);
      setInventoryItemsModel(tempInventoryItems);
    }
    return {
      tempJobTypes: tempJobTypes,
      tempInventoryItems: tempInventoryItems,
    };
  };

  const getServiceItemObj = (item) => {
    return convertServiceItemObject(item, true);
  };

  const populateFromVisitServiceItems = (serviceItemsFromVisit) => {
    var itemComps = [...itemComplimentaryList];
    if (!Utils.IsNullOrEmptyArray(serviceItemsFromVisit)) {
      serviceItemsFromVisit.forEach((serviceitem) => {
        var newItem = getServiceItemObj(serviceitem);
        var comp = extractItemComplimentaryFromServiceItem(newItem);
        if (!Utils.IsNullOrEmptyObject(comp)) {
          itemComps.push(comp);
        }
        if (!Utils.IsNullOrEmpty(newItem)) {
          itemDispatch({
            type: serviceItemActionTypes.Add,
            value: newItem,
          });
        }
      });
      setItemComplimentaryList(itemComps);
    }
  };
  const extractItemComplimentaryFromServiceItem = (serviceItem) => {
    if (
      !Utils.IsNullOrEmptyArray(serviceItem.Discounts) &&
      serviceItem.Discounts.some((d) => d.Complimentary && !d.OrderLevel)
    ) {
      var discount = serviceItem.Discounts.filter(
        (d) => d.Complimentary && !d.OrderLevel
      )[0];
      if (Utils.IsNullOrEmptyObject(discount)) {
        return {};
      }
      return {
        orderLevel: false,
        productId: serviceItem.ProductId,
        index: serviceItem.Sequence,
        value: discount.DiscountValue,
        type: discount.DiscountType,
        discount: discount.DiscountTotal,
        compId: discount.CampaignId,
      };
    }
    return {};
  };
  const [signatureImage, setSignatureImage] = useState("");

  const showInvoice = async () => {
    if (Utils.IsNullOrEmptyArray(serviceItems)) {
      setAlert("Please add atleast one item before proceeding");
      return;
    }
    if (!validServiceItemQuantity()) {
      return;
    }
    if (!validServiceItemWithDiscount()) {
      return;
    }
    var serviceDetails = generateServiceDetails();
    var visit = generateTemporaryVisit(
      serviceItems,
      customerId,
      VisitTypeEnum.Jobcard,
      vehicle,
      serviceDetails,
      selectedLabel.taxIncluded
    );
    if (Utils.IsNullOrEmptyObject(visit)) {
      return;
    }
    var request = {
      CountryCode: branch.countryCode,
      CustomerVisit: visit,
      Mask: getUser().maskCustomerDetails,
      IsPreview: true,
    };
    setIsLoading(true);
    var result = await postAsync("Customer/InvoiceBeforePayment", request);
    if (result.error) {
      setIsLoading(false);
      setIsNavigateToLogin(result.isNavigateToLogin);
      setAlert(result.errorMessage);
      return;
    }
    let child = window.open("about:blank", "myChild");
    child.document.write(result.data);
    child.document.close();
    setIsLoading(false);
  };

  const generateServiceDetails = () => {
    var inventoryDataList = [];
    inventoryItemsModel.forEach((item) => {
      if (item.isChecked) {
        inventoryDataList.push({
          Name: item.key,
          AdditionalData: item.additionalData,
        });
      }
    });
    var selectedJobTypes = [];
    jobTypesModel.forEach((item) => {
      if (item.isChecked) {
        selectedJobTypes.push(item.value);
      }
    });
    var selectedTechnicians = [];
    multipleTechniciansModel.forEach((item) => {
      if (item.isChecked) {
        selectedTechnicians.push({
          Id: item.TechnicianId,
          Name: item.TechnicianName,
          Status: item.Status,
        });
      }
    });
    var selectedAdvisor = !Utils.IsNullOrEmpty(serviceAdvisor)
      ? JSON.parse(serviceAdvisor)
      : {};
    var estimatedRepairDays = parseInt(insuranceEstimatedRepairDays);
    var estimateValidity = parseInt(insuranceEstimateValidity);
    return {
      PromisedDateTime: Utils.GetISODateTimeString(promisedDate), // customerPromisedDate
      JobCardDoneBy: customerJobcardDoneBy,
      TechnicianName: !Utils.IsNullOrEmptyObject(selectedAdvisor)
        ? selectedAdvisor.userName
        : "",
      TechnicianId: !Utils.IsNullOrEmptyObject(selectedAdvisor)
        ? selectedAdvisor.id
        : "",
      JobInspectionBy: customerJobcardInspBy,
      CustomerRequest: customerComments,
      NextServiceInDays: Utils.ConvertToInteger(nextServiceInDays),
      NextServiceOn: Utils.GetISODateTimeString(nextServiceDT),
      JobTypes: selectedJobTypes,
      InventoryData: inventoryDataList,
      Technicians: selectedTechnicians,
      ImageUrls: vehicleImageUrls,
      CustomerSignatureUrl: signatureImage,
      InsuranceClaimNumber: insuranceClaimNumber,
      InsuranceEstimatedRepairDays: isNaN(estimatedRepairDays)
        ? 0
        : estimatedRepairDays,
      InsuranceEstimateValidity: isNaN(estimateValidity) ? 0 : estimateValidity,
      InsuranceType: insuranceType,
      InsuranceInDate: Utils.GetISODateTimeString(insuranceInDate),
      InsuranceOutDate: Utils.GetISODateTimeString(insuranceOutDate),
    };
  };

  const saveVisit = async (payment = false) => {
    var newStart = null;
    if (Utils.IsNullOrEmptyObject(visit)) {
      newStart = {
        ServiceLocationType: ServiceLocationTypes.ServiceStartLocation,
        CapturedDT: Utils.GetCurrentDate().format(),
      };
    }
    var newEnd = null;
    if (payment) {
      newEnd = {
        ServiceLocationType: ServiceLocationTypes.ServiceEndLocation,
        CapturedDT: Utils.GetCurrentDate().format(),
      };
    }
    var tempServiceItems = [...serviceItems];
    if (
      tempServiceItems.some(
        (t) =>
          t.TotalPrice === 0 &&
          t.DiscountTotal > 0 &&
          !Utils.IsNullOrEmptyArray(t.Discounts)
      )
    ) {
      tempServiceItems.forEach((serviceItem, index) => {
        if (
          serviceItem.TotalPrice === 0 &&
          serviceItem.DiscountTotal > 0 &&
          !Utils.IsNullOrEmptyArray(serviceItem.Discounts)
        ) {
          serviceItem.Discounts = [];
          serviceItem.DiscountTotal = 0;
          var action = {
            type: serviceItemActionTypes.Edit,
            value: serviceItem,
          };
          handleServiceItemActions(action);
        }
      });
    }
    var tempServiceItems = [...serviceItems];
    if (
      tempServiceItems.some(
        (t) =>
          t.TotalPrice === 0 &&
          t.DiscountTotal > 0 &&
          !Utils.IsNullOrEmptyArray(t.Discounts)
      )
    ) {
      tempServiceItems.forEach((serviceItem, index) => {
        if (
          serviceItem.TotalPrice === 0 &&
          serviceItem.DiscountTotal > 0 &&
          !Utils.IsNullOrEmptyArray(serviceItem.Discounts)
        ) {
          serviceItem.Discounts = [];
          serviceItem.DiscountTotal = 0;
          var action = {
            type: serviceItemActionTypes.Edit,
            value: serviceItem,
          };
          handleServiceItemActions(action);
        }
      });
    }
    var serviceDetails = generateServiceDetails();
    var newReq = {
      BookingRef: Utils.IsNullOrEmpty(visit)
        ? Utils.CreateGuid()
        : visit.bookingRef,
      BusinessId: business.id,
      CustomerId: customerId,
      Comments: customerComments,
      PaymentMethod: "Cash",
      DeletedImages: deleteImageUrls,
      ServiceItems: serviceItems,
      VisitStatus: "",
      VisitType: "Jobcard",
      VisitId: !Utils.IsNullOrEmptyObject(visit) ? visit.id : "",
      EstimateId: !Utils.IsNullOrEmptyObject(estimate) ? estimate.id : "",
      AdditionalData: {
        CustomerEmailId: customerEmailId,
        CustomerMobileNumber: customerMobileNumber,
        CustomerTaxId: customerGSTINCode,
        InvoiceLabelDescription: selectedLabel.description,
        IsTaxIncluded: selectedLabel.taxIncluded,
        VehicleDetails: {
          Id: Utils.IsNullOrEmptyObject(vehicle) ? 0 : vehicle.id,
          Number: customerVehicleNo,
          VehicleType: vehicleType,
          plateCode: customerPlateCode,
          emirate: customerEmirate,
          Make: customerVehicleMake,
          Model: customerVehicleModel,
          Color: customerVehicleColor,
          Year: Utils.ConvertToInteger(customerVehicleYear),
          OwnerContactNo: customerMobileNumber,
          EngineNumber: customerVehicleengineNo,
          ChassisNumber: customerVehicleChassisNo,
          FuelLevel: customerVehicleFuelLevel,
          FuelType: customerVehicleFuelType,
          KmDriven: Utils.ConvertToInteger(odoMeterIn),
          KmDrivenOut: Utils.ConvertToInteger(odoMeterOut),
          AverageMileage: customerVehicleAverageMileage,
          TyreCondition: customerVehicleTyreCondition,
          VehicleInsuranceDetails: customerVehicleInsData,
        },
        ServiceDetails: serviceDetails,
        JobCardDoneBy: customerJobcardDoneBy,
        ServiceStartLocation: Utils.IsNullOrEmptyObject(serviceStartLocation)
          ? newStart
          : serviceStartLocation,
        ServiceEndLocation: payment ? newEnd : null,
      },
      VisitIdForJobCard: !Utils.IsNullOrEmpty(visit) ? "" : visitId,
    };
    let result = await postAsync("Customer/InitiateVisit", newReq);
    if (result.error === true) {
      return result;
    }
    setVisit(result.data.customerVisit);
    return result;
  };

  const handleJobcardError = (jobcardError) => {
    switch (jobcardError) {
      case JobcardErrorTypes.Refunded:
        setAlert(
          "Unable to edit refunded job card. Editing refunded job cards is not permitted."
        );
        setJobcardAction(jobcardActions.BackNavigationTriggered);
        break;
      case JobcardErrorTypes.LowerPrice:
        setAlert(
          "Unable to edit jobcard. New price cannot be lower than the amount already paid."
        );
        break;
      case JobcardErrorTypes.UnableToEdit:
        setAlert(
          "Action not allowed! The payment for the job card has already been successfully processed."
        );
        break;
    }
  };

  const isEditJobcardValid = async () => {
    if (Utils.IsNullOrEmptyObject(visit)) {
      return JobcardErrorTypes.None;
    }
    setIsLoading(true);
    var result = await postAsync(
      "Customer/GetCustomerVisit",
      { BusinessId: business.id, Id: visit.id },
      "",
      "Get"
    );
    setIsLoading(false);
    if (result.error === true) {
      setIsNavigateToLogin(result.isNavigateToLogin);
      setAlert(result.errorMessage);
      return JobcardErrorTypes.Error;
    }
    if (Utils.IsNullOrEmptyObject(result.data)) {
      return JobcardErrorTypes.None;
    }
    if (result.data.status === VisitStatuses.PaymentRefunded) {
      return JobcardErrorTypes.Refunded;
    }
    if (result.data.status === VisitStatuses.Delivered && !isEditJobcard) {
      return JobcardErrorTypes.UnableToEdit;
    }
    // var _totalAmount = Utils.IsNullOrEmptyArray(serviceItems) ? 0 : serviceItems
    //   .map((s) => s.TotalPriceIncludingTax)
    //   .reduce((a, b) => a + b);
    // var _discount = Utils.IsNullOrEmptyArray(serviceItems) ? 0 : serviceItems
    //   .map((s) => s.DiscountTotal)
    //   .reduce((a, b) => a + b);
    // var _refundTotal = Utils.IsNullOrEmptyArray(serviceItems) ? 0 : serviceItems
    //   .map((s) => s.RefundTotal)
    //   .reduce((a, b) => a + b);
    // var _actualAmount = _totalAmount - _discount - _refundTotal;
    // if (
    //   _actualAmount < result.data.paidAmount ||
    //   _actualAmount < result.data.paidAmount + result.data.requestedAmount
    // ) {
    //   return JobcardErrorTypes.LowerPrice;
    // }
    return JobcardErrorTypes.None;
  };

  const validServiceItemWithDiscount = () => {
    if (!Utils.IsNullOrEmptyArray(serviceItems)) {
      var invalidServiceItems = serviceItems.filter(
        (s) => s.TotalPrice < s.DiscountTotal
      );

      if (invalidServiceItems.length > 0) {
        var itemDescriptions = invalidServiceItems
          .map((item) => item.Description || "an item")
          .join(",");

        setAlert(`The price for the following service items is lower than the added discount:\n\n${itemDescriptions}`);
        return false;
      }
    }
    return true;
  };

  const validServiceItemQuantity = () => {
    if (!Utils.IsNullOrEmptyArray(serviceItems)) {
      var invalidServiceItems = serviceItems.filter(
        (c) => c.Quantity === 0 || c.Quantity === ""
      );

      if (invalidServiceItems.length > 0) {
        var itemDescriptions = invalidServiceItems
          .map((item) => item.Description || "an item")
          .join(", ");

        setAlert(`Quantity for the following service items must be greater than 0: ${itemDescriptions}`);
        return false;
      }
    }
    return true;
  };

  const submitJobcard = async () => {
    if (!validServiceItemQuantity()) {
      return;
    }
    if (!validServiceItemWithDiscount()) {
      return;
    }
    var errorResult = await isEditJobcardValid();
    if (errorResult !== JobcardErrorTypes.None) {
      handleJobcardError(errorResult);
      return;
    }
    setShowDialog(false);
    setIsLoading(true);
    var updatedVisit = await saveVisit();
    setIsLoading(false);
    if (updatedVisit.error === true) {
      setIsNavigateToLogin(updatedVisit.isNavigateToLogin);
      setAlert(updatedVisit.errorMessage);
      return;
    }
    updatedVisit = updatedVisit.data.customerVisit;
    if (Utils.IsNullOrEmptyObject(updatedVisit)) {
      setAlert("Something went wrong");
      return;
    }
    setJobcardAction(jobcardActions.Saved);
    setAlertMessage("Jobcard saved successfully");
    setShowAlert(true);
  };
  const viewInsuranceUEABusiness = () => {
    setIsShowConfirmDialogInsDetails(true);
  };

  const viewInsuranceEstimate = async (bool) => {
    if (isUAEBusiness) {
      var show = bool;
    } else {
      var show = false;
    }
    if (Utils.IsNullOrEmptyArray(serviceItems)) {
      setShowEstimateOptions(false);
      setAlert("Please add atleast one item befor proceeding");
      return;
    }
    if (!isUAEBusiness) {
      if (
        Utils.IsNullOrEmptyObject(vehicle.vehicleInsuranceDetails) ||
        Utils.IsNullOrEmpty(vehicle.vehicleInsuranceDetails.policyNumber) ||
        Utils.ConvertToDate(vehicle.vehicleInsuranceDetails.expiryDate).isBefore(
          moment()
        )
      ) {
        setShowEstimateOptions(false);
        setAlert(
          "Please add insurance details for the selected vehicle before proceeding"
        );
        return;
      }
    }
    var errorResult = await isEditJobcardValid();
    if (errorResult !== JobcardErrorTypes.None) {
      handleJobcardError(errorResult);
      return;
    }
    setIsLoading(true);
    var updatedVisit = await saveVisit();
    setIsLoading(false);
    if (updatedVisit.error === true) {
      setIsNavigateToLogin(updatedVisit.isNavigateToLogin);
      setShowEstimateOptions(false);
      setAlert(updatedVisit.errorMessage);
      return;
    }
    updatedVisit = updatedVisit.data.customerVisit;
    var url =
      Utils.GetServiceUrl() +
      "home/InsuranceEstimate?culture=" +
      CountryHelper.GetAPICulture(countryInfo.Culture) +
      "&bid=" +
      business.id +
      "&cid=" +
      updatedVisit.customerId +
      "&vid=" +
      updatedVisit.id +
      "&isInsurance=" +
      true +
      "&showPrices=" +
      show +
      "&m=0";
    window.open(encodeURI(url), "_blank");
    setIsShowConfirmDialogInsDetails(false);
  };

  const viewJobcard = async (estimate) => {
    if (Utils.IsNullOrEmptyArray(serviceItems)) {
      setShowEstimateOptions(false);
      setAlert("Please add atleast one item befor proceeding");
      return;
    }
    if (!validServiceItemQuantity()) {
      return;
    }
    if (!validServiceItemWithDiscount()) {
      return;
    }
    var errorResult = await isEditJobcardValid();
    if (errorResult !== JobcardErrorTypes.None) {
      handleJobcardError(errorResult);
      return;
    }
    setIsLoading(true);
    var updatedVisit = await saveVisit();
    setIsLoading(false);
    if (updatedVisit.error === true) {
      setShowEstimateOptions(false);
      setAlert(updatedVisit.errorMessage);
      return;
    }
    updatedVisit = updatedVisit.data.customerVisit;
    var url =
      Utils.GetServiceUrl() +
      "home/JobCard?culture=" +
      CountryHelper.GetAPICulture(countryInfo.Culture) +
      "&bid=" +
      business.id +
      "&cid=" +
      updatedVisit.customerId +
      "&vid=" +
      updatedVisit.id +
      "&estimate=" +
      estimate +
      "&m=0";
    window.open(encodeURI(url), "_blank");
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleJobTypesSelection = (event, index) => {
    configureDialog();
    const tempJobTypes = [...jobTypesModel];
    tempJobTypes[index].isChecked = event.target.checked;
    setJobTypesModel(tempJobTypes);
  };

  const handleInventoryItemsSelection = (event, index) => {
    configureDialog();
    const tempInventoryItems = [...inventoryItemsModel];
    tempInventoryItems[index].isChecked = event.target.checked;
    setInventoryItemsModel(tempInventoryItems);
  };

  const handleInventoryAdditionalData = (event, index) => {
    configureDialog();
    const tempInventoryItems = [...inventoryItemsModel];
    tempInventoryItems[index].additionalData = event.target.value;
    setInventoryItemsModel(tempInventoryItems);
  };

  const handleMultipleTechnicianSelection = (event, index) => {
    configureDialog();
    const tempMultipleTechnicians = [...multipleTechniciansModel];
    multipleTechniciansModel[index].isChecked = event.target.checked;
    setMultipleTechniciansModel(tempMultipleTechnicians);
  };

  useEffect(() => {
    onChangeNextServiceInDays();
  }, [nextServiceInDays]);

  const onChangeNextServiceInDays = () => {
    var daysToAdd =
      Utils.ConvertToInteger(nextServiceInDays) > 0
        ? Utils.ConvertToInteger(nextServiceInDays)
        : 1;
    var nextDT = moment().add(daysToAdd, "days");
    setNextServiceDT(nextDT);
  };

  const payment = async () => {
    if (Utils.IsNullOrEmptyArray(serviceItems)) {
      setAlert("Please add service items before payment");
      return;
    }
    if (!validServiceItemQuantity()) {
      return;
    }
    if (!validServiceItemWithDiscount()) {
      return;
    }
    var errorResult = await isEditJobcardValid();
    if (errorResult !== JobcardErrorTypes.None) {
      handleJobcardError(errorResult);
      return;
    }
    setShowDialog(false);
    setIsLoading(true);
    var updatedVisit = await saveVisit(true);
    setIsLoading(false);
    if (updatedVisit.error === true) {
      setIsNavigateToLogin(updatedVisit.isNavigateToLogin);
      setAlert(updatedVisit.errorMessage);
      return;
    }
    updatedVisit = updatedVisit.data.customerVisit;
    if (Utils.IsNullOrEmptyObject(updatedVisit)) {
      setAlert("Something went wrong");
      return;
    }
    navigate(PaymentPage.Path, {
      state: {
        ...location.state,
        checkout: false,
        serviceItems: serviceItems,
        visit: updatedVisit,
        pass: pass,
        vehicleDetails: updatedVisit.vehicleDetails,
        fromVisit: location.state.fromVisit,
        fromJobcard: location.state.fromJobcard,
        isEdit: Utils.IsNullOrEmpty(location.state.isEdit)
          ? false
          : location.state.isEdit,
        validVehiclesCount: location.state.validVehiclesCount,
        searchText:
          location.state && location.state.searchText
            ? location.state.searchText
            : "",
        navigationStack: Utils.AddElementToArray(
          location.state.navigationStack,
          JobCardDetailsPage.Path
        ),
        itemComps: itemComplimentaryList,
        selectedLabel: selectedLabel.description,
      },
    });
  };

  const handleServiceItemActions = (action) => {
    switch (action.type) {
      case serviceItemActionTypes.Clear:
        setItemComplimentaryList([]);
        break;
      case serviceItemActionTypes.Delete:
        var itemComps = [...itemComplimentaryList];
        var serviceItem = serviceItems[action.value];
        if (
          !Utils.IsNullOrEmptyArray(serviceItem) &&
          !Utils.IsNullOrEmptyArray(serviceItem.Discounts)
        ) {
          itemComps = itemComps.filter((i) => i.index !== serviceItem.Sequence);
          setItemComplimentaryList(itemComps);
        }
        break;
    }
    configureDialog();
    itemDispatch(action);
  };
  const handleEditItemComp = (comp, i) => {
    var comps = [...itemComplimentaryList];
    comps[i] = comp;
    setItemComplimentaryList(comps);
  };

  function valuetext(value) {
    return value.toString();
  }

  const handleAlertClose = () => {
    setShowAlert(false);
    setJobcardAction("");
    if (isNavigateToLogin) {
      UserHelper.LogOut(dispatch);
      navigate(LoginPage.Path);
      return;
    }
    switch (jobcardAction) {
      case jobcardActions.Saved:
        navigate(location.state.onSuccess, {
          state: {
            ...location.state,
            navigationStack: [],
          },
        });
        break;
      case jobcardActions.BackNavigationTriggered:
        backNavigation();
        break;
    }
  };

  const addItemToCart = (product, isCustomProduct = false) => {
    configureDialog();
    var serviceItem = isCustomProduct
      ? product
      : getServiceItemFromProduct(product);
    if (Utils.IsNullOrEmptyArray(serviceItems)) {
      serviceItem.Sequence = 1;
    } else {
      serviceItem.Sequence = serviceItems[serviceItems.length - 1].Sequence + 1;
    }
    itemDispatch({
      type: serviceItemActionTypes.Add,
      value: serviceItem,
    });
  };

  const backNavigation = () => {
    navigate(Utils.GetLastArrayElement(location.state.navigationStack), {
      state: {
        ...location.state,
        navigationStack: Utils.RemoveLastElementFromArray(
          location.state.navigationStack
        ),
      },
    });
  };

  useEffect(() => {
    checkDetailsFilled();
  }, [
    customerMobileNumber,
    customerEmailId,
    customerGSTINCode,
    customerVehicleNo,
    customerEmirate,
    customerPlateCode,
    customerVehicleMake,
    customerVehicleModel,
    customerVehicleFuelType,
    customerVehicleengineNo,
    customerVehicleChassisNo,
    odoMeterIn,
    customerUserName,
    serviceItems,
    customerComments,
    customerJobcardDoneBy,
    inventoryItemsModel,
    jobTypesModel,
    customerVehicleFuelLevel,
    promisedDate,
    serviceAdvisor,
    multipleTechniciansModel,
  ]);

  const checkDetailsFilled = () => {
    var phoneNumberValid = Utils.IsMobileValid(customerMobileNumber);
    var emailValid =
      Utils.IsNullOrEmpty(customerEmailId) ||
      Utils.IsEmailValid(customerEmailId);

    var gstValid = Utils.IsNullOrEmpty(customerGSTINCode);
    if (!gstValid) {
      var tempCountryInfo = countryInfo;
      if (Utils.IsNullOrEmptyObject(tempCountryInfo)) {
        tempCountryInfo = getCountry();
      }
      switch (tempCountryInfo.Code) {
        case UAE.Code:
          gstValid = customerGSTINCode.length === 15;
          break;
        case India.Code:
          gstValid = Regexes.GST.test(customerGSTINCode);
          break;
      }
    }
    var vehicleDetailsValid =
      !Utils.IsNullOrEmpty(customerVehicleNo) &&
      !Utils.IsNullOrEmpty(customerVehicleMake) &&
      !Utils.IsNullOrEmpty(customerVehicleModel) &&
      !Utils.IsNullOrEmpty(customerVehicleFuelType) &&
      (Utils.IsNullOrEmpty(customerVehicleengineNo) ||
        Regexes.AlphaNumeric.test(customerVehicleengineNo)) &&
      (Utils.IsNullOrEmpty(customerVehicleChassisNo) ||
        Regexes.AlphaNumeric.test(customerVehicleChassisNo));
    var odoMeterValid =
      !branch.isOdometerReadingsMandatory ||
      Utils.ConvertToInteger(odoMeterIn) > 0;
    var isDetailsFilled =
      phoneNumberValid &&
      emailValid &&
      gstValid &&
      vehicleDetailsValid &&
      !Utils.IsNullOrEmpty(customerUserName) &&
      (!Utils.IsNullOrEmptyArray(serviceItems) ||
        !Utils.IsNullOrEmpty(customerComments)) &&
      !Utils.IsNullOrEmpty(customerJobcardDoneBy) &&
      odoMeterValid;
    setSaveDisabled(!isDetailsFilled);
  };

  const onChangeMobileNumber = (value) => {
    setCustomerMobileNumber(value);
    configureDialog();
  };

  const onChangeAddress = (value) => {
    setCustomerAddress(value);
    configureDialog();
  };

  const onChangeVehicleYear = (value) => {
    setCustomerVehicleYear(value);
    configureDialog();
  };

  const onChangeNextServiceInDaysTextField = (value) => {
    setNextServiceInDays(value);
    configureDialog();
  };

  const onChangeOdoMeterIn = (value) => {
    setOdoMeterIn(value);
    configureDialog();
  };

  const configureDialog = () => {
    if (!showDialog) {
      setShowDialog(true);
    }
  };
  const onAddComp = (comp) => {
    var tempCompList = [...itemComplimentaryList];
    tempCompList.push(comp);
    setItemComplimentaryList(tempCompList);
  };

  const onRemoveComp = (comp) => {
    var tempCompList = [...itemComplimentaryList];
    tempCompList = tempCompList.filter((t) => t.compId !== comp.compId);
    setItemComplimentaryList(tempCompList);
  };

  return (
    <Container maxWidth="false" className="bg-color p-0">
      <Loader open={isLoading} />
      <Box>
        {/* Popups */}
        <PromptDialog
          showDialog={showPrompt}
          confirmNavigation={confirmNavigation}
          cancelNavigation={cancelNavigation}
        />

        <RetryPopup
          retryOpen={showRetry}
          retryText={retryMessage}
          onRetry={async () => await onCloseRetry()}
        />

        <MessagePopup
          msgOpen={showAlert}
          msgText={alertMessage}
          onMsgClose={handleAlertClose}
        />

        {/* ins confirm */}
        <InsuranceConfirmDialog
          open={isShowConfirmDialogInsDetails}
          close={() => setIsShowConfirmDialogInsDetails(false)}
          yes={() => {
            viewInsuranceEstimate(true);
          }}
          no={() => {
            viewInsuranceEstimate(false);
          }}
        />

        {/* Estimate Option */}
        <EstimateOptionsDialog
          open={showEstimateOptions}
          close={() => setShowEstimateOptions(false)}
          regularEstimate={() => viewJobcard(true)}
          insuranceEstimate={() => {
            if (!validServiceItemQuantity()) {
              return;
            }
            if (!validServiceItemWithDiscount()) {
              return;
            }
            isUAEBusiness
              ? setIsShowConfirmDialogInsDetails(true)
              : viewInsuranceEstimate(true)
          }}
        />

        {/* Main Container */}
        <Grid container m={0}>
          <SideBarMenuItems
            selectedTab={
              !Utils.IsNullOrEmptyObject(location.state) &&
                !Utils.IsNullOrEmpty(location.state.activeMenu)
                ? location.state.activeMenu
                : location.state && location.state.fromJobcard
                  ? SideBarMenuNames.JobCard
                  : location.state && location.state.fromVisit
                    ? SideBarMenuNames.Overview
                    : SideBarMenuNames.Customers
            }
          />
          <Grid
            xs={12}
            className="content-sec"
            style={{ background: "#ffffff" }}
          >
            <Grid container className="title-sec">
              <Typography
                level="h2"
                component="h2"
                fontWeight="600"
                fontSize="2rem"
                className="page-title text-center"
              >
                <Button onClick={backNavigation} className="back-btn">
                  <NavigateBeforeIcon />
                </Button>
                Job Card Details
              </Typography>
              <Grid paddingRight="15px" alignSelf="center" className="toolbar">
                <Stack direction="row" spacing={1}>
                  <Button
                    className="btn btn-secondary"
                    variant="contained"
                    disabled={saveDisabled}
                    onClick={async () => await showInvoice()}
                  >
                    <Box
                      component="img"
                      sx={{
                        width: 20,
                      }}
                      alt="Preview"
                      src={Preview_Icon}
                    />
                    <span className="hidden-xs ml-h">Preview</span>
                  </Button>
                  <Button
                    className="btn btn-secondary"
                    variant="contained"
                    disabled={saveDisabled}
                    onClick={async () => await viewJobcard(false)}
                  >
                    <PrintIcon style={{ color: "#ff9800" }} />
                    <span className="hidden-xs ml-h">Print Jobcard</span>
                  </Button>
                  <Button
                    className="btn btn-secondary"
                    variant="contained"
                    disabled={saveDisabled}
                    onClick={() => {
                      setShowEstimateOptions(true);
                    }}
                  >
                    <ShareIcon style={{ color: "#673ab7" }} />
                    <span className="hidden-xs ml-h">Share Estimate</span>
                  </Button>
                  <Button
                    className="btn btn-secondary"
                    variant="contained"
                    disabled={saveDisabled}
                    onClick={async () => await submitJobcard()}
                  >
                    <CheckCircleOutlineIcon style={{ color: "#8BC34A" }} />
                    <span className="hidden-xs ml-h">Save</span>
                  </Button>
                  <Button
                    className="btn btn-secondary"
                    variant="contained"
                    disabled={saveDisabled}
                    onClick={async () => await payment()}
                  >
                    <CreditScoreIcon style={{ color: "#607D8B" }} />
                    <span className="hidden-xs ml-h">Payment</span>
                  </Button>
                </Stack>
              </Grid>
            </Grid>
            <form
              method="POST"
              className="cs-form text-left mt-xs-3"
              autoComplete="off"
            >
              <Typography
                level="h3"
                component="h3"
                fontWeight="600"
                fontSize="2rem"
                className="h3"
              >
                Customer Info :
              </Typography>
              <Stack sx={{ minWidth: 120 }} spacing={2} className="mt-2">
                <Stack direction="row" spacing={2}>
                  <FormControl fullWidth>
                    <TextField
                      id="jobcardName"
                      label="Name"
                      variant="outlined"
                      error={!customerUserName}
                      value={customerUserName}
                      onChange={(e) => {
                        setCustomerUserName(e.target.value);
                        configureDialog();
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormControl>
                  {isUAEBusiness ? (
                    <FormControl fullWidth>
                      <TextField
                        id="jobcardGSTIN"
                        label={"Tax Id"}
                        variant="outlined"
                        value={customerGSTINCode}
                        inputProps={{ maxLength: 15 }}
                        error={
                          Utils.IsNullOrEmpty(customerGSTINCode)
                            ? false
                            : customerGSTINCode.length !== 15
                        }
                        onChange={(e) => {
                          setCustomerGSTINCode(e.target.value);
                          configureDialog();
                        }}
                      />
                    </FormControl>
                  ) : (
                    <FormControl fullWidth>
                      <TextField
                        id="jobcardGSTIN"
                        label={"Business GST"}
                        variant="outlined"
                        value={customerGSTINCode}
                        inputProps={{ maxLength: 15 }}
                        error={
                          Utils.IsNullOrEmpty(customerGSTINCode)
                            ? false
                            : !Regexes.GST.test(customerGSTINCode)
                        }
                        onChange={(e) => {
                          setCustomerGSTINCode(e.target.value);
                          configureDialog();
                        }}
                      />
                    </FormControl>
                  )}
                </Stack>
                <Stack direction="row" spacing={2}>
                  <FormControl fullWidth>
                    <NumericTextField
                      id="jobcardMobileNumber"
                      label="Mobile Number"
                      variant="outlined"
                      value={customerMobileNumber}
                      error={!Utils.IsMobileValid(customerMobileNumber)}
                      inputProps={{
                        maxLength: CountryHelper.GetMobileNumberMaxLength(),
                      }}
                      setValue={onChangeMobileNumber}
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      id="jobcardEmailId"
                      label="Email"
                      variant="outlined"
                      value={customerEmailId}
                      error={
                        Utils.IsNullOrEmpty(customerEmailId)
                          ? false
                          : !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(customerEmailId)
                      }
                      onChange={(e) => {
                        setCustomerEmailId(e.target.value);
                        configureDialog();
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormControl>
                </Stack>
                {!Utils.IsNullOrEmptyObject(countryInfo) && (
                  <FormControl fullWidth>
                    <AddressField
                      id="jobcardAddress"
                      variant="outlined"
                      label="Address"
                      countryISoCode={countryInfo.ISOCode}
                      value={customerAddress}
                      setValue={onChangeAddress}
                    />
                  </FormControl>
                )}
                {!Utils.IsNullOrEmptyArray(vehicleImageUrls) && (
                  <div>
                    <Typography
                      level="h3"
                      component="h3"
                      fontWeight="600"
                      fontSize="2rem"
                      className="h3"
                    >
                      Vehicle Images :
                    </Typography>
                    <Stack
                      direction="row"
                      spacing={2}
                      className="card"
                      useFlexGap
                      flexWrap="wrap"
                    >
                      {vehicleImageUrls.map((url) => (
                        <img src={url} width="200px" />
                      ))}
                    </Stack>
                  </div>
                )}
                <Typography
                  level="h3"
                  component="h3"
                  fontWeight="600"
                  fontSize="2rem"
                  className="h3"
                >
                  Vehicle Details :
                </Typography>
                <Stack direction="row" spacing={2}>
                  {isUAEBusiness && (
                    <>
                      <FormControl fullWidth>
                        <TextField
                          id="Emirate"
                          label="Emirate"
                          variant="outlined"
                          value={customerEmirate}
                          disabled={emirateDisabled}
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <TextField
                          id="PlateCode"
                          label="Plate Code"
                          variant="outlined"
                          disabled={plateCodeDisabled}
                          value={customerPlateCode}
                        />
                      </FormControl>
                    </>
                  )}
                  <FormControl fullWidth>
                    <TextField
                      id="jobcardVehicleNumber"
                      label="Vehicle Number"
                      variant="outlined"
                      error={!customerVehicleNo}
                      value={customerVehicleNo}
                      onChange={(e) => {
                        setCustomerVehicleNo(e.target.value);
                        configureDialog();
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      id="jobcardMake"
                      label="Make"
                      variant="outlined"
                      error={!customerVehicleMake}
                      value={customerVehicleMake}
                      onChange={(e) => {
                        setCustomerVehicleMake(e.target.value);
                        configureDialog();
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormControl>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <FormControl fullWidth>
                    <TextField
                      id="jobcardModel"
                      label="Model"
                      variant="outlined"
                      error={!customerVehicleModel}
                      value={customerVehicleModel}
                      onChange={(e) => {
                        setCustomerVehicleModel(e.target.value);
                        configureDialog();
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <NumericTextField
                      id="jobcardYear"
                      label="Year"
                      variant="outlined"
                      value={customerVehicleYear}
                      setValue={onChangeVehicleYear}
                      inputProps={{ maxLength: 4 }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      id="jobcardColor"
                      label="Color"
                      variant="outlined"
                      value={customerVehicleColor}
                      onChange={(e) => {
                        setCustomerVehicleColor(e.target.value);
                        configureDialog();
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormControl>
                </Stack>
                <Stack direction="row" spacing={2} className="xs-column">
                  <FormControl fullWidth className="my-xs-1">
                    <InputLabel id="demo-simple-select-label">
                      Fuel Type
                    </InputLabel>
                    <Select
                      id="jobcardFuelType"
                      value={customerVehicleFuelType}
                      label="Fuel Type"
                      variant="outlined"
                      onChange={() => handleChange}
                    >
                      <MenuItem value="Petrol">Petrol</MenuItem>
                      <MenuItem value="Diesel">Diesel</MenuItem>
                      <MenuItem value="CNG">CNG</MenuItem>
                      <MenuItem value="LPG">LPG</MenuItem>
                      <MenuItem value="Electric">Electric</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl fullWidth className="my-xs-1">
                    <NumericTextField
                      id="jobcardOdometerReadingIn"
                      value={odoMeterIn}
                      setValue={onChangeOdoMeterIn}
                      label="Odometer Reading In"
                      variant="outlined"
                      required={branch.isOdometerReadingsMandatory}
                    />
                  </FormControl>
                  <FormControl fullWidth className="my-xs-1">
                    <TextField
                      id="jobcardOdometerReadingOut"
                      value={odoMeterOut}
                      disabled={true}
                      label="Odometer Reading Out"
                      variant="outlined"
                    />
                  </FormControl>
                </Stack>
                <Stack direction="row" spacing={2} className="xs-column">
                  <FormControl fullWidth className="my-xs-1">
                    <TextField
                      id="jobcardKMPL"
                      label="Average Mileage (KMPL)"
                      variant="outlined"
                      value={customerVehicleAverageMileage}
                      onChange={(e) => {
                        setCustomerVehicleAverageMileage(e.target.value);
                        configureDialog();
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormControl>
                  <FormControl fullWidth className="my-xs-1">
                    <TextField
                      id="jobcardTyreCondition"
                      label="Tyre Condition (%)"
                      variant="outlined"
                      value={customerVehicleTyreCondition}
                      onChange={(e) => {
                        setCustomerVehicleTyreCondition(e.target.value);
                        configureDialog();
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormControl>
                  <FormControl fullWidth className="my-xs-1">
                    <TextField
                      id="jobcardChassisNumber"
                      label="Chassis Number"
                      variant="outlined"
                      value={customerVehicleChassisNo}
                      onChange={(e) => {
                        setCustomerVehicleChassisNo(e.target.value);
                        configureDialog();
                      }}
                    />
                  </FormControl>
                </Stack>
                <Stack direction="row" spacing={2} className="xs-column">
                  <FormControl fullWidth className="my-xs-1">
                    <TextField
                      id="jobcardEngineNumber"
                      label="Engine Number"
                      variant="outlined"
                      value={customerVehicleengineNo}
                      onChange={(e) => {
                        setCustomerVehicleengineNo(e.target.value);
                        configureDialog();
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth className="my-xs-1">
                    <NumericTextField
                      id="jobcardNextServiceInDays"
                      type="number"
                      value={nextServiceInDays}
                      setValue={onChangeNextServiceInDaysTextField}
                      label="Next Service In (Days)"
                      variant="outlined"
                    />
                  </FormControl>
                  <FormControl fullWidth className="my-xs-1">
                    <TextField
                      type="date"
                      id="Next Service Date"
                      disabled={true}
                      value={Utils.GetInputDateFormat(nextServiceDT)}
                      label="Next Service Date"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormControl>
                </Stack>
                {isUAEBusiness && (
                  <InsuranceClaimComponent
                    insuranceClaimNumber={insuranceClaimNumber}
                    setInsuranceClaimNumber={setInsuranceClaimNumber}
                    insuranceType={insuranceType}
                    setInsuranceType={setInsuranceType}
                    insuranceEstimatedRepairDays={insuranceEstimatedRepairDays}
                    setInsuranceEstimatedRepairDays={
                      setInsuranceEstimatedRepairDays
                    }
                    insuranceEstimateValidity={insuranceEstimateValidity}
                    setInsuranceEstimateValidity={setInsuranceEstimateValidity}
                    insuranceInDate={insuranceInDate}
                    setInsuranceInDate={setInsuranceInDate}
                    insuranceOutDate={insuranceOutDate}
                    setInsuranceOutDate={setInsuranceOutDate}
                  />
                )}
                <Typography
                  level="h3"
                  component="h3"
                  fontWeight="600"
                  fontSize="2rem"
                  className="h3"
                >
                  Job Type :
                </Typography>
                <FormGroup row="true">
                  {jobTypesModel.map((jobType, i) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={jobType.isChecked}
                          onChange={(e) => handleJobTypesSelection(e, i)}
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                        />
                      }
                      label={jobType.value}
                    />
                  ))}
                </FormGroup>
                <Box>
                  <Typography
                    level="h3"
                    component="h3"
                    fontWeight="600"
                    fontSize="2rem"
                    className="h3"
                  >
                    Service Items :
                  </Typography>
                  <Stack className="card">
                    <CartComponent
                      includePreview={false}
                      serviceItems={serviceItems}
                      handleItemAction={handleServiceItemActions}
                      onAddItem={addItemToCart}
                      visitType={VisitTypeEnum.Jobcard}
                      invoiceLabels={branch.invoiceLabels}
                      itemComplimentaryList={itemComplimentaryList}
                      onAddComp={onAddComp}
                      onRemoveComp={onRemoveComp}
                      onEditComp={handleEditItemComp}
                      setSelectedLabel={setSelectedLabel}
                      selectedLabel={selectedLabel}
                    />
                  </Stack>
                </Box>
                <Typography
                  level="h3"
                  component="h3"
                  fontWeight="600"
                  fontSize="2rem"
                  className="h3"
                >
                  Inventory Details :
                </Typography>
                <Stack direction="row" spacing={2}>
                  <Typography
                    level="p"
                    component="p"
                    fontSize="2rem"
                    className="h3"
                    width="100px"
                  >
                    Fuel Level %
                  </Typography>
                  <Slider
                    aria-label="Fuel Level"
                    color="primary"
                    valueLabelDisplay="auto"
                    getAriaValueText={valuetext}
                    value={customerVehicleFuelLevel}
                    onChange={(e) => {
                      setCustomerVehicleFuelLevel(e.target.value);
                      configureDialog();
                    }}
                  />
                </Stack>
                <FormGroup row="true">
                  {inventoryItemsModel.map((inventoryItem, i) => (
                    <Box>
                      <FormControlLabel
                        control={
                          <Box
                            className="card"
                            style={{ padding: "0px 10px 0 0" }}
                          >
                            <Checkbox
                              checked={inventoryItem.isChecked}
                              onChange={(e) =>
                                handleInventoryItemsSelection(e, i)
                              }
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                              style={{ "vertical-align": "center" }}
                            />
                            <span>{inventoryItem.key}</span>
                            {inventoryItem.value === true && (
                              <TextField
                                type="text"
                                value={inventoryItem.additionalData}
                                onChange={(e) =>
                                  handleInventoryAdditionalData(e, i)
                                }
                                placeholder="Additional Info"
                                disabled={!inventoryItem.isChecked}
                                className="add-info"
                              />
                            )}
                          </Box>
                        }
                      ></FormControlLabel>
                    </Box>
                  ))}
                </FormGroup>
                <Typography
                  level="h3"
                  component="h3"
                  fontWeight="600"
                  fontSize="2rem"
                  className="h3"
                >
                  Customer's Request :
                </Typography>
                <FormControl fullWidth>
                  <TextField
                    id="jobcardComments"
                    label="Comments"
                    variant="outlined"
                    value={customerComments}
                    onChange={(e) => setCustomerComments(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    multiline
                  />
                </FormControl>
                <Stack direction="row" spacing={2} className="xs-column">
                  <FormControl fullWidth className="my-xs-1">
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        id="businessOpen"
                        label="Promised Date"
                        value={promisedDate}
                        disablePast={true}
                        onChange={(newValue) => setPromisedDate(newValue)}
                        closeOnSelect={true}
                      />
                    </LocalizationProvider>
                  </FormControl>
                  <FormControl fullWidth className="my-xs-1">
                    <TextField
                      id="jobcardDoneBy"
                      label="Job Card done by"
                      variant="outlined"
                      error={!customerJobcardDoneBy}
                      value={customerJobcardDoneBy}
                      onChange={(e) => setCustomerJobcardDoneBy(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormControl>
                  <FormControl fullWidth className="my-xs-1">
                    <InputLabel id="demo-simple-select-label">
                      Service Advisor
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={serviceAdvisor || ""}
                      label="Service Advisor"
                      onChange={(e) => setServiceAdvisor(e.target.value)}
                      style={{ height: "53px" }}
                    >
                      {!Utils.IsNullOrEmptyArray(techniciansList) &&
                        techniciansList.map((tech) => (
                          <MenuItem value={JSON.stringify(tech)}>
                            {tech.userName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth className="my-xs-1">
                    <TextField
                      id="jobcardInspBy"
                      label="Job Insp by"
                      variant="outlined"
                      value={customerJobcardInspBy}
                      onChange={(e) => setCustomerJobcardInspBy(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormControl>
                </Stack>
                <Grid container xs={12} className="mt-2">
                  <Grid xs={12} className="">
                    <Typography
                      level="h3"
                      component="h3"
                      fontWeight="600"
                      fontSize="2rem"
                      className="h3"
                    >
                      Multiple Technicians :
                    </Typography>
                    <Stack
                      direction="row"
                      spacing={2}
                      className="mt-1"
                      style={{ overflow: "auto" }}
                    >
                      {!Utils.IsNullOrEmptyArray(multipleTechniciansModel) &&
                        multipleTechniciansModel.map((tech, i) => (
                          <label
                            style={{
                              "margin-right": "6px",
                              "margin-left": "0px",
                            }}
                          >
                            <Stack
                              direction="column"
                              spacing={2}
                              className="justify-content-between card"
                              padding={1}
                            >
                              <Typography
                                level="h6"
                                component="h6"
                                className="h6 text-center"
                              >
                                <input
                                  type="checkbox"
                                  checked={tech.isChecked}
                                  value={tech.TechnicianName}
                                  onChange={(e) =>
                                    handleMultipleTechnicianSelection(e, i)
                                  }
                                />{" "}
                                &nbsp;
                                {tech.TechnicianName}
                              </Typography>
                              <Typography
                                level="h6"
                                component="h6"
                                fontWeight="600"
                                fontSize="14px"
                                className="h6 text-center m-0"
                              >
                                {tech.TechnicianId}
                              </Typography>
                            </Stack>
                          </label>
                        ))}
                    </Stack>
                  </Grid>
                  {!Utils.IsNullOrEmpty(signatureImage) && (
                    <Grid xs={2} style={{ "padding-right": "20px" }}>
                      <Typography
                        level="h3"
                        component="h3"
                        fontWeight="600"
                        fontSize="2rem"
                        className="h3"
                      >
                        Signature :
                      </Typography>
                      <div className="card imgBox">
                        <img
                          alt="not found"
                          width={"150px"}
                          src={signatureImage}
                        />
                        <br />
                        <button
                          className="p-0 mt-1 btn btn-default"
                          onClick={() => setSignatureImage("")}
                        >
                          <DeleteIcon />
                        </button>
                      </div>
                    </Grid>
                  )}
                </Grid>
              </Stack>
            </form>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default JobCardDetails;
