import TextField from "@mui/material/TextField";
import Utils from "../../../core/Utils";
import PropTypes from "prop-types";
import { OfferTypes } from "../../../core/Constants";
import { Regexes } from "../../../core/Regexes";
const CompValueEntryField = ({
  value,
  setValue,
  compType,
  maxLimit,
  ...other
}) => {
  const handleOnChange = (currentValue) => {
    if (Utils.IsNullOrEmpty(currentValue)) {
      setValue("");
    }
    var oldValue = value;
    var val = currentValue;
    if (Regexes.onlyDecimalValue.test(val)) {
      var newPrice = val;
      switch (compType) {
        case OfferTypes.PercentDiscount:
        case "Discount":
          var tempLimit = maxLimit < 1 ? 0 : 100
          if (newPrice <= tempLimit && newPrice >= 0) {
            if (newPrice > 0 || newPrice >= 0) {
              setValue(newPrice);
            } else {
              setValue("");
            }
          } else {
            setValue(oldValue);
          }
          break;
        case OfferTypes.Cash:
          if (newPrice <= maxLimit && newPrice >= 0) {
            if (newPrice > 0 || newPrice >= 0) {
              setValue(newPrice);
            } else {
              setValue("");
            }
          } else {
            setValue(oldValue);
          }
          break;
      }
    }
  };
  return (
    <TextField
      {...other}
      value={value}
      onChange={(e) => handleOnChange(e.target.value)}
    />
  );
};

export default CompValueEntryField;
CompValueEntryField.propTypes = {
  value: PropTypes.number.isRequired,
  setValue: PropTypes.func.isRequired,
  maxLimit: PropTypes.number.isRequired,
  compType: PropTypes.string.isRequired,
};
